import "./index.scss"
import React, { useState, useEffect } from "react"
import { useForm } from "react-form"
import Button from "../../common/button/button"
import styles from "../../common/button/button.module.scss"
import TextArea from "../../common/form/text-area/text-area"
import ReCAPTCHA from "react-google-recaptcha"
import { MdError } from "react-icons/md"
import { getAdminEmails } from "../../../services/moderation"
import moderationService from "../../../services/moderation"
import { sendEmails } from "../../../services/moderation"
import { NotificationManager } from "react-notifications"
import { NotificationContainer } from "react-notifications"
import "react-notifications/lib/notifications.css"

const FlagCorrection = ({ captchaRef, item, setModalOpen}) => {
  const [submitAttempted, setSubmitAttempted] = useState()
  const [email, setEmail] = useState()
  const onChange = value => {
    console.log("Captcha Verified")
    return
  }
  const formInstance = useForm({
    debugForm: false,
    onSubmit: values => {
      setModalOpen(false)
      console.log("onSubmit", values)

      const id = item.branch && item.branch.id ? item.branch.id : item.branch
      console.log("Item", item)
      getAdminEmails(id).then(mailToAddrs => {
        console.log("mailToAddrs", mailToAddrs)

        const mailObj = {
          subject: "Inappropriate Media",
          ...values,
          adminEmails: mailToAddrs,
        }
        sendEmails(mailObj).then(emailOptions => {
          if (mailToAddrs !== mailObj && mailToAddrs === mailObj) {
            NotificationManager.error("Error sending email!")
          } else {
            NotificationManager.success(
              "Email has been Sent Successfully!",
              "Successful!",
              2000
            )
          }
          setEmail(emailOptions)
        })
      })
    },
    validate: (values, instance) => {
      setSubmitAttempted(false)
    },
  })

  const { Form, meta, pushFieldValue } = formInstance

  return (
    <div>
      <Form>
        <TextArea
          label="Media contains inappropriate information:"
          field="message"
          validate={value => {
            return !value ? "You need to write a Message!" : false
          }}
          mandatory
        />
        <ReCAPTCHA
          sitekey={"6LdmL-IcAAAAANxGQHaMvTxSbO4fQRgHuOCvqu3s"}
          onChange={onChange}
        />
        <div className={styles.action}>
          <div className={styles.buttonWrap}>
            <br />
            <NotificationContainer />
            <Button
              id="save-data"
              ref={captchaRef}
              label="Send Email"
              type="submit"
              btnStyle="primary"
              btnSize="small"
              fullWidth
              prependIcon="MdSend"
              // onClick={closeModal}
            />
          </div>
        </div>
      </Form>
    </div>
  )
}
export default FlagCorrection