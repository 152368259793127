import axios from "axios"
import _ from "lodash"

const _items = []
let _email = null

export const getItems = () => {
  return _items
}

export const setItems = items => {
  _items.length = 0
  Array.prototype.push.apply(_items, items)
}

export const getAllItemsReadState = () => {
  var _items = getItems()
  var currentValue = _.uniq(_.map(_items, "itemRead"))

  if (currentValue.length > 1) {
    currentValue = false
  } else {
    currentValue = currentValue[0] == true
  }
  return currentValue
}

export const approve = async (items, getDuplicates) => {
  return new Promise(function(resolve, reject) {
    const mediaItems = {
      getDuplicates,
      items: items
    }

    console.log('Media:', mediaItems)
    if (items.length > 0) {
      axios.put("/moderation/approve", mediaItems).then(
        () => resolve(true),
        error => {
          reject(error)
          console.log("moderation.approve error:", error)
        }
      )
    } else {
      resolve(true)
    }
  })
}

export const reject = async items => {
  return new Promise(function(resolve, reject) {
    if (items.length > 0) {
      axios.put("/moderation/reject", items).then(
        () => resolve(true),
        error => {
          reject(error)
          console.log("moderation.reject error:", error)
        }
      )
    } else {
      resolve(true)
    }
  })
}

const submitCorrection = item => {
  const subject = "Media Correction"
  const body =
    "This media contains incorrect information:\n\n" +
    generateUrlFromItem(item) +
    "\n\n" +
    "The following information: \n\n" +
    "Should be changed to: "
  doMailTo(item, subject, body)
}

const flagInappropriate = item => {
  const subject = "Inappropriate Media"
  const body =
    "This media contains inappropriate information:\n\n" +
    generateUrlFromItem(item)
  doMailTo(item, subject, body)
}

const generateUrlFromItem = item => {
  const mediaType = item.type === "Story" ? "Story" : "Media"
  return (
    window.origin +
    "/detailed-file-view" +
    "?mediaId=" +
    item.id +
    "&mediaType=" +
    mediaType
  )

  // http://localhost:8000/detailed-file-view?mediaId=5e46462f3d706a22a43d42f1&mediaType=Story
}

const doMailTo = async (item, subject, body) => {
  var id = item.branch && item.branch.id ? item.branch.id : item.branch
  getAdminEmails(id).then(mailToAddrs => {
    const link = document.createElement("a")
    link.target = "_top"
    const href =
      "mailto:" +
      mailToAddrs.join(",") +
      "?subject=" +
      encodeURIComponent(subject) +
      "&body=" +
      encodeURIComponent(body)
    link.href = href
    console.log(href)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  })
}

export const getAdminEmails = async branchId => {
  console.log("getAdmins", "/moderation/getAdminEmail/" + branchId)
  return new Promise(function(resolve, reject) {
    if (_email) {
      resolve(_email)
    } else {
      axios.get("/moderation/getAdminEmail/" + branchId).then(
        response => resolve(response.data),
        error => {
          reject(error)
          console.log("moderation.getAdminEmail error:", error)
        }
      )
    }
  })
}

export const sendEmails = async emailOptions => {
  console.log("emailOptions", emailOptions)
  return new Promise(function(resolve, reject) {
    axios.post("moderation/email", emailOptions).then(response => {
      console.log("Email sent Successfully", response)
      resolve(response.data),
        error => {
          reject(error)
          console.log("moderation/send:", error)
        }
    })
  })
}

export default {
  getAllItemsReadState,
  getItems,
  setItems,
  approve,
  reject,
  submitCorrection,
  getAdminEmails,
  flagInappropriate,
  sendEmails,
}