import "./index.scss"
import React, { useState, useEffect } from "react"
import { useForm } from "react-form"
import Button from "../../common/button/button"
import styles from "../../common/button/button.module.scss"
import TextArea from "../../common/form/text-area/text-area"
import ReCAPTCHA from "react-google-recaptcha"
import { getAdminEmails } from "../../../services/moderation"
import { sendEmails } from "../../../services/moderation"
import { NotificationManager } from "react-notifications"
import { NotificationContainer } from "react-notifications"
import "react-notifications/lib/notifications.css"
// import { Grid, Divider } from "@material-ui/core"
// import sgMail from "../../services/send-mail"

const SubmitCorrection = ({ captchaRef, item, setModalOpen }) => {
  const [submitAttempted, setSubmitAttempted] = useState()
  const [email, setEmail] = useState()
  const onChange = value => {
    console.log("Captcha Verified")
  }

  const formInstance = useForm({
    debugForm: false,
    onSubmit: values => {
      setModalOpen(false)
      console.log("onSubmit", values)
      const id = item.branch && item.branch.id ? item.branch.id : item.branch
      console.log("Item", item)
      getAdminEmails(id).then(mailToAddrs => {
        console.log("mailToAddrs", mailToAddrs)

        const mailObj = {
          subject: "Media Correction",
          ...values,
          adminEmails: mailToAddrs,
        }
        sendEmails(mailObj).then(emailOptions => {
          setEmail(emailOptions)
        })
      })
      NotificationManager.success(
        "Email has been Sent Successfully!",
        "Successful!",
        2000
      )
    },
    validate: (values, instance) => {
      setSubmitAttempted(false)
    },
  })

  const { Form, meta, pushFieldValue } = formInstance

  return (
    <div>
      <Form>
        <div>
          <TextArea
            label="Media contains incorrect information:"
            field="message"
            validate={value => {
              return !value ? "You need to write a Description!" : false
            }}
            mandatory
          />
        </div>
        <TextArea
          label="Media Should be changed to:"
          field="description"
          validate={value => {
            return !value ? "You need to write a Message!" : false
          }}
          mandatory
        />
        <ReCAPTCHA
          sitekey={"6LdmL-IcAAAAANxGQHaMvTxSbO4fQRgHuOCvqu3s"}
          onChange={onChange}
        />
        <div className={styles.action}>
          <div className={styles.buttonWrap}>
            <br />
            <NotificationContainer />
            <Button
              id="save-data"
              ref={captchaRef}
              label="Send Email"
              type="submit"
              btnStyle="primary"
              btnSize="small"
              fullWidth
              prependIcon="MdSend"
            />
          </div>
        </div>
      </Form>
    </div>
  )
}
export default SubmitCorrection