import React, { useState, useRef, useEffect, useCallback } from "react"
import PropTypes from "prop-types"

import {
  CompositeDecorator,
  Editor,
  EditorState,
  RichUtils,
  getDefaultKeyBinding,
  AtomicBlockUtils,
  Entity,
  convertFromRaw,
  convertToRaw,
  Modifier,
  SelectionState,
} from "draft-js"
import styles from "./story-editor.module.scss"
import {
  MdFormatUnderlined,
  MdFormatBold,
  MdFormatItalic,
  MdFormatAlignJustify,
  MdFormatAlignLeft,
  MdFormatAlignRight,
  MdFormatListBulleted,
  MdFormatListNumbered,
  MdFormatIndentDecrease,
  MdFormatIndentIncrease,
  MdInsertLink,
  MdInsertPhoto,
  MdCancel,
} from "react-icons/md"
import classNames from "classnames"
import { splitFormProps, useField } from "react-form"
import AttachMedia from "../../attach-media/attach-media"
import ModalWindow from "../../modal-window/modal-window"
import ErrorMessage from "../error-message/error-message"
import util from "../../../../services/util"

// const baseUrl = process.env.GATSBY_FEDIA_DMS_PORT && process.env.GATSBY_FEDIA_DMS_PORT !== '80' && process.env.GATSBY_FEDIA_DMS_PORT !== 80 ? 
//   `https://${process.env.GATSBY_FEDIA_DMS_HOST}:${process.env.GATSBY_FEDIA_DMS_PORT}/` :
//   `http://${process.env.GATSBY_FEDIA_DMS_HOST}/`;

const fixUrl = webViewUrl => {
  let baseUrl = ''
  if (webViewUrl.includes('http://')) {
    baseUrl = util.baseUrl()
  }
  else {
    baseUrl = util.baseUrl().replace('http://', '')
  }
  return webViewUrl ? `${util.baseUrl()}${webViewUrl.replace(baseUrl, '').replace('http://AppSt-Fedia-PQ7U6WD4330R-317694051.ca-central-1.elb.amazonaws.com/','')}` : webViewUrl;
}

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity()
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    )
  }, callback)
}

const Link = props => {
  const { url } = props.contentState.getEntity(props.entityKey).getData()
  return (
    <a href={url} style={styles.link}>
      {props.children}
    </a>
  )
}

const StoryEditor = props => {
  const [field, fieldOptions, { suppressError }] = splitFormProps(props)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  const {
    meta: { error, isTouched, isValidating },
    value,
    setValue,
  } = useField(field, fieldOptions)

  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ])

  const [linkInputState, setLinkInputState] = useState({
    showURLInput: false,
    urlValue: "",
  })
  const [modalOpen, setModalOpen] = useState(false)

  var createValue = EditorState.createEmpty(decorator)
  if (value && value.getBlockMap) {
    createValue = EditorState.createWithContent(value, decorator)
  }
  const [editorState, setEditorState] = useState(createValue)
  const [alignment, setAlignment] = useState("left")
  const [blockType, setBlockType] = useState("")
  const [curInlineStyle, setCurInlineStyle] = useState(new Map())

  // var test = convertToRaw(editorState.getCurrentContent())
  // console.log("current editorState:", test)

  useEffect(() => {
    const selection = editorState.getSelection()
    setBlockType(
      editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType()
    )
  }, [editorState])

  useEffect(() => setCurInlineStyle(editorState.getCurrentInlineStyle()), [
    editorState,
  ])

  useEffect(() => setValue(editorState.getCurrentContent()), [editorState])

  // useEffect(() => {
  //   if (value) {
  //     if (value && value.hasOwnProperty("getBlockMap")) {
  //       setEditorState(EditorState.createWithContent(value, decorator))
  //     }
  //   }
  // }, [value])

  const editor = useRef(null)
  const urlInput = useRef(null)

  const focus = useCallback(() => {
    if (editor.current) editor.current.focus()
  }, [])

  const promptForLink = e => {
    console.log('Link')
    e.preventDefault()
    const selection = editorState.getSelection()
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent()
      const startKey = editorState.getSelection().getStartKey()
      const startOffset = editorState.getSelection().getStartOffset()
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey)
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset)

      let url = ""
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey)
        url = linkInstance.getData().url
      }

      console.log('URL:', url)
      setLinkInputState({
        showURLInput: true,
        urlValue: url,
      })
    }
  }

  const confirmLink = e => {
    e.preventDefault()
    const { urlValue } = linkInputState
    const contentState = editorState.getCurrentContent()
    const contentStateWithEntity = contentState.createEntity(
      "LINK",
      "MUTABLE",
      { url: urlValue, color: "blue" }
    )
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    })
    setEditorState(
      RichUtils.toggleLink(
        newEditorState,
        newEditorState.getSelection(),
        entityKey
      )
    )
    setLinkInputState({
      showURLInput: false,
      urlValue: "",
    })
  }

  const cancelLink = e => {
    e.preventDefault();
    setLinkInputState({
      showURLInput: false,
      urlValue: "",
    })
  }

  const onLinkInputKeyDown = e => {
    if (e.which === 13) {
      confirmLink(e)
    }
  }

  const removeLink = e => {
    e.preventDefault()
    const selection = editorState.getSelection()
    if (!selection.isCollapsed()) {
      setEditorState(RichUtils.toggleLink(editorState, selection, null))
    }
  }

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      setEditorState(newState)
      return "handled"
    }
    return "not-handled"
  }

  const mapKeyToEditorCommand = e => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(e, editorState, 4 /* maxDepth */)
      if (newEditorState !== editorState) {
        setEditorState(newEditorState)
      }
      return false
    }
    return getDefaultKeyBinding(e)
  }

  const onBoldClick = () => {
    const newState = RichUtils.toggleInlineStyle(editorState, "BOLD")
    if (newState) {
      setEditorState(newState)
    }
  }

  const onItalicClick = () => {
    const newState = RichUtils.toggleInlineStyle(editorState, "ITALIC")
    if (newState) {
      setEditorState(newState)
    }
  }

  const onUnderlineClick = () => {
    const newState = RichUtils.toggleInlineStyle(editorState, "UNDERLINE")
    if (newState) {
      setEditorState(newState)
    }
  }

  const onCenterAlignClick = () => {
    setAlignment("center")
  }

  const onLeftAlignClick = () => {
    setAlignment("left")
  }

  const onRightAlignClick = () => {
    setAlignment("right")
  }

  const onUnorderedListClick = () => {
    const newState = RichUtils.toggleBlockType(
      editorState,
      "unordered-list-item"
    )
    if (newState) {
      setEditorState(newState)
    }
  }

  const onOrderedListClick = () => {
    const newState = RichUtils.toggleBlockType(editorState, "ordered-list-item")
    if (newState) {
      setEditorState(newState)
    }
  }

  const changeIndent = (e, indentDirection) => {
    e.preventDefault()
    if (indentDirection && indentDirection === "decrease") {
      e.shiftKey = true
    }
    const currentBlockType = RichUtils.getCurrentBlockType(editorState)
    if (
      currentBlockType === "ordered-list-item" ||
      currentBlockType === "unordered-list-item"
    ) {
      setEditorState(RichUtils.onTab(e, editorState, 4))
    }
  }

  const onIncreaseIndentClick = e => changeIndent(e, "increase")
  const onDecreaseIndentClick = e => changeIndent(e, "decrease")

  let urlInputContainer
  if (linkInputState.showURLInput) {
    urlInputContainer = (
      <div className={styles.urlInputContainer}>
        <input
          onChange={e => {
            const val = e.target.value
            setLinkInputState(s => ({ ...s, urlValue: val }))
          }}
          ref={urlInput}
          style={styles.urlInput}
          type="text"
          value={linkInputState.urlValue}
          onKeyDown={onLinkInputKeyDown}
        />
        <button onMouseDown={confirmLink}>Confirm</button>
        <button type="button" onMouseDown={cancelLink}>Cancel</button>
      </div>
    )
  }

  const onAttachMediaClick = e => {
    setModalOpen(true)
  }

  const removeMediaHandler = (block, mediaId) => {
    if (props.removeMediaHandler) {
      props.removeMediaHandler(mediaId)
    }

    var contentState = editorState.getCurrentContent()
    var blockMap = contentState.getBlockMap()
    var newBlockMap = blockMap.remove(block.getKey())
    var newContentState = contentState.merge({
      blockMap: newBlockMap,
    })
    var newEditorState = EditorState.push(
      editorState,
      newContentState,
      "remove-range"
    )

    setEditorState(newEditorState)
  }

  const Media = props => {
    const entRef = props.block.getEntityAt(0)
    if (!entRef) return null
    const entity = Entity.get(entRef)

    const { readOnly, removeMediaHandler } = props.blockProps
    const { src, mediaId } = entity.getData()
    const type = entity.getType()

    let mediaInner
    if (type === "image") {
      mediaInner = <img src={fixUrl(src)} />
    }

    let mediaFull = <div className={styles.mediaWrapper}>{mediaInner}</div>
    if (!readOnly) {
      mediaFull = (
        <div className={styles.mediaWrapper}>
          <MdCancel
            onClick={e => {
              e.preventDefault()
              removeMediaHandler(props.block, mediaId)
            }}
            className={classNames({
              [styles.closeIcon]: true,
            })}
          />
          <span className={styles.media}>{mediaInner}</span>
        </div>
      )
    }
    return mediaFull
  }

  const mediaBlockRenderer = block => {
    if (block.getType() === "atomic") {
      return {
        component: Media,
        props: {
          readOnly: props.readOnly,
          removeMediaHandler: removeMediaHandler,
        },
      }
    }
    return null
  }

  const onMediaAttached = attachedMedia => {
    if (props.attachMediaHandler) {
      props.attachMediaHandler(attachedMedia)
    }

    const entityKey = Entity.create("image", "IMMUTABLE", {
      src: fixUrl(attachedMedia.webViewUrl),
      mediaId: attachedMedia.id,
    })
    const newState = AtomicBlockUtils.insertAtomicBlock(
      editorState,
      entityKey,
      fixUrl(attachedMedia.webViewUrl)
    )
    console.log(
      convertToRaw(editorState.getCurrentContent()),
      convertToRaw(newState.getCurrentContent()),
      Entity.get(entityKey)
    )
    setEditorState(newState)

    setModalOpen(false)
  }

  const getModalWindow = () => {
    const onModalClose = () => {
      setModalOpen(false)
    }
    if (modalOpen) {
      return (
        <ModalWindow title="Insert Image" closeHandler={onModalClose}>
          <AttachMedia onMediaAttached={onMediaAttached} mediaType={'Photo'}/>
        </ModalWindow>
      )
    } else {
      return ""
    }
  }

  const getToolbar = () => {
    if (props.readOnly) {
      return ""
    } else {
      return (
        <ul className={styles.toolbar}>
          <li>
            <button
              type="button"
              onClick={onUnderlineClick}
              className={classNames({
                [styles.active]: curInlineStyle.has("UNDERLINE"),
              })}
            >
              <MdFormatUnderlined />
            </button>
          </li>
          <li>
            <button
              type="button"
              onClick={onBoldClick}
              className={classNames({
                [styles.active]: curInlineStyle.has("BOLD"),
              })}
            >
              <MdFormatBold />
            </button>
          </li>
          <li>
            <button
              type="button"
              onClick={onItalicClick}
              className={classNames({
                [styles.active]: curInlineStyle.has("ITALIC"),
              })}
            >
              <MdFormatItalic />
            </button>
          </li>
          <li>
            <button
              type="button"
              onClick={onLeftAlignClick}
              className={classNames({
                [styles.active]: alignment === "left",
              })}
            >
              <MdFormatAlignLeft />
            </button>
          </li>
          <li>
            <button
              type="button"
              onClick={onCenterAlignClick}
              className={classNames({
                [styles.active]: alignment === "center",
              })}
            >
              <MdFormatAlignJustify />
            </button>
          </li>

          <li>
            <button
              type="button"
              onClick={onRightAlignClick}
              className={classNames({
                [styles.active]: alignment === "right",
              })}
            >
              <MdFormatAlignRight />
            </button>
          </li>
          <li>
            <button
              type="button"
              onClick={onUnorderedListClick}
              className={classNames({
                [styles.active]: blockType === "unordered-list-item",
              })}
            >
              <MdFormatListBulleted />
            </button>
          </li>
          <li>
            <button
              type="button"
              onClick={onOrderedListClick}
              className={classNames({
                [styles.active]: blockType === "ordered-list-item",
              })}
            >
              <MdFormatListNumbered />
            </button>
          </li>
          <li>
            <button type="button" onClick={onDecreaseIndentClick}>
              <MdFormatIndentDecrease />
            </button>
          </li>
          <li>
            <button type="button" onClick={onIncreaseIndentClick}>
              <MdFormatIndentIncrease />
            </button>
          </li>
          <li>
            <button type="button" onClick={promptForLink}>
              <MdInsertLink />
            </button>
          </li>
          <li>
            <button type="button" onClick={onAttachMediaClick}>
              <MdInsertPhoto />
            </button>
          </li>
        </ul>
      )
    }
  }

  return (
    <div
      className={classNames(
        styles.wrapper,
        props.readOnly ? styles.readMode : ""
      )}
    >
      {getModalWindow()}
      {getToolbar()}
      {urlInputContainer}
      <div
        onClick={focus}
        className={classNames(
          styles.editor,
          props.readOnly ? styles.readMode : ""
        )}
      >
        {error && !suppressError ? <ErrorMessage errorMessage={error} /> : ""}
        {mounted ? <Editor
          ref={editor}
          readOnly={props.readOnly}
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={mapKeyToEditorCommand}
          onChange={editorState => setEditorState(editorState)}
          blockRendererFn={mediaBlockRenderer}
          textAlignment={alignment}
        /> : ""}
      </div>
    </div>
  )
}

StoryEditor.defaultProps = {
  readOnly: false,
}

StoryEditor.propTypes = {
  field: PropTypes.string.isRequired,
  attachMediaHandler: PropTypes.func,
  removeMediaHandler: PropTypes.func,
  errorMessage: PropTypes.string,
}

export default StoryEditor
