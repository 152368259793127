/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { ToastContainer } from "react-toastify"
import LocationProvider from "../../common/location-provider/location-provider"
import ModalWrapper from "../../common/modal/modal-wrapper/modal-wrapper"
import SearchWrapper from "../../common/search-wrapper/search-wrapper"
import SearchHeader from "../search-header/search-header"
import "./search-layout.scss"

const SearchLayout = ({ children, location, searchPath }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleSearchQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  console.log("rendering search layout")

  return (
    <LocationProvider location={location}>
      <ModalWrapper />

      <SearchWrapper index="media" searchPath={searchPath}>
        <SearchHeader siteTitle={data.site.siteMetadata.title} />
        <main> {children} </main>
        <ToastContainer />
      </SearchWrapper>
    </LocationProvider>
  )
}

SearchLayout.defaultProps = {
  searchPath: "search",
}

SearchLayout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
  searchPath: PropTypes.string,
}

export default SearchLayout
