import React, { useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import styles from "./daterange.module.scss"
import { MdError, MdDateRange } from "react-icons/md"
import classNames from "classnames"
import Label from "../label/label"

import { useField, splitFormProps } from "react-form"
import moment from "moment"

const DateRange = props => {
  const [isFocus, setFocus] = useState(false)

  // Let's use splitFormProps to get form-specific props
  const [field, fieldOptions, { onChange }] = splitFormProps(props)

  // Use the useField hook with a field and field options
  // to access field state
  const fieldInstance = useField(field, fieldOptions)
  const {
    value,
    setValue,
    meta: { error, isTouched },
  } = fieldInstance

  const getStartDate = () => {
    return value && value.startDate ? value.startDate : null
  }

  const getEndDate = () => {
    return value && value.endDate ? value.endDate : null
  }

  const updateRange = newRange => {
    setValue(newRange)
    if (onChange) {
      onChange(newRange)
    }
  }

  const handleStartChange = date => {
    updateRange({
      startDate: date,
      endDate: getEndDate(),
    })
  }

  const handleEndChange = date => {
    updateRange({
      startDate: getStartDate(),
      endDate: date,
    })
  }

  return (
    <div className={styles.dateRange}>
      <div className={classNames(styles.yearMonthPicker, "year-month-wrapper")}>
        <Label label="From" />
        <div
          className={classNames("year-month-wrap", isFocus ? " active" : "")}
        >
          <div className="icon">
            <MdDateRange />
          </div>

          <DatePicker
            showYearDropdown={true}
            showMonthDropdown={true}
            dropdownMode="select"
            selected={getStartDate()}
            onChange={handleStartChange}
            selectsStart
            startDate={getStartDate()}
            endDate={getEndDate()}
            dateFormat="MM/yyyy"
          />
        </div>
      </div>

      <div className={classNames(styles.yearMonthPicker, "year-month-wrapper")}>
        <Label label="To" />
        <div
          className={classNames("year-month-wrap", isFocus ? " active" : "")}
        >
          <div className="icon">
            <MdDateRange />
          </div>

          <DatePicker
            showYearDropdown={true}
            showMonthDropdown={true}
            dropdownMode="select"
            selected={getEndDate()}
            onChange={handleEndChange}
            selectsEnd
            minDate={getStartDate()}
            startDate={getStartDate()}
            endDate={getEndDate()}
            dateFormat="MM/yyyy"
          />
        </div>
      </div>

      {isTouched && error ? (
        <div
          className="icon error-icon"
          data-tip={error}
          data-event="click focus"
        >
          <MdError />
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default DateRange
