import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { ReactiveComponent } from "@appbaseio/reactivesearch"
import { useForm } from "react-form"
import Checkbox from "../../common/form/check-box/check-box"
import SearchAutocomplete from "../filters/search-autocomplete/search-autocomplete"
import SearchCheckboxes from "../filters/search-checkboxes/search-checkboxes"
import SearchDateRange from "../filters/search-daterange/search-daterange"
import SearchText from "../filters/search-text/search-text"
import styles from "./filter-files.module.scss"
import "./filter-files.scss"
import moment from "moment"
import classNames from "classnames"

const FilterFiles = ({
  availableFilters,
  simpleVerticalSearch,
  lastRefreshTime,
  hideFilters,
  onClearHandler
}) => {
  // const [lastRefreshTime, setLastRefreshTime] = useState('')  
  var defaultSearchParamsObj = {
    title: null,
    location: { value: "" },
    people: { value: "" },
    type: null,
    copyright: null,
    branch: null, //branch.name
   

   date: {
      startDate: moment("18700101", "YYYYMMDD").toDate(),
      endDate: new Date(),
    },
    dateUnknown: null, 
    multi: null,
  }

  const defaultValues = React.useMemo(() => defaultSearchParamsObj, [])
  const formInstance = useForm({
    defaultValues: defaultValues,
  })
  const { Form } = formInstance

  const onClearClick = event => {
    if (onClearHandler) {
      onClearHandler()
    }
    // this clears the inputs ( or resets the default when available )
    formInstance.setValues(defaultSearchParamsObj)
  }

  const getHeader = () => {
    if (simpleVerticalSearch) {
      return ""
    } else {
      return (
        <>
          <h5>Search and Filter</h5>
          <div className={styles.head}>
            <span>Filter by</span>
            <a onClick={onClearClick}>Clear</a>
          </div>
        </>
      )
    }
  }

  const showFilter = filterName => {
    return (
      !availableFilters ||
      availableFilters == "all" ||
      availableFilters.indexOf(filterName) !== -1
    )
  }

  const handleClick = () => {
    setLastRefreshTime(new Date())
  }

  const getRefresher = () => {
    return (
    <ReactiveComponent
        componentId="refresher"
        customQuery={props => ({
            query: {
                bool: {
                    must: {
                        match: {
                            _index: "media"
                        }
                    },
                    must_not: {
                        term: { dummy: String(lastRefreshTime) }
                    }
                }
            }
        })}
    />
    )
  }

  const getTypeFilter = () => {
    if (showFilter("type")) {
      return (
        <div className={styles.container}>
          <div className={styles.title}>Media Type</div>
          <SearchCheckboxes
            formInstance={formInstance}
            componentId="searchType"
            options={[
              { label: "Photo", name: "Photo" },
              { label: "Video", name: "Video" },
              { label: "Audio", name: "Audio" },
              { label: "Document", name: "Document" },
              { label: "Archive", name: "Archive" },
              { label: "Story", name: "Story" },
            ]}
            fieldName="type"
            defaultValues={[]}
            showFilter={false}
          />
        </div>
      )
    } else {
      return ""
    }
  }
  const getDateFilter = () => {
    if (showFilter("date")) {
      return (
         <div className={styles.container}>
         <div className={styles.title}>Filter by date</div>
        
          <SearchDateRange
              formInstance={formInstance}
              allowEmpty={true}
              componentId="searchCaptureDate"
              fieldName="date"
              showFilter={false}

            />
            <SearchCheckboxes
              formInstance={formInstance}
              componentId="searchDateUnknown"
              options={[
                { label: "Unknown Date", name: "true" }
              ]}
              fieldName="isDateUnknown"
              showFilter={false}
            />
      </div>
      )
    } else {
      return ""
    }
  }
  
  const getKeywordsFilter = () => {
    if (showFilter("keywords")) {
      return (
        <SearchAutocomplete
          componentId="searchKeywords"
          index="keyword"
          label="Keyword"
          placeholder="Search"
          fieldName="keywords"
          dataField="term"
          clearOnSelect={true}
          filterLabel="for "
          allowMultiple={true}
        />
      )
    } else {
      return ""
    }
  }
  const getTitleFilter = () => {
    if (showFilter("title")) {
      return (
        <SearchText
          formInstance={formInstance}
          componentId="searchTitle"
          type="text"
          label="Title"
          placeholder="Title"
          fieldName="title"
          prependIcon="MdSearch"
          showFilter={false}
          queryType="match_phrase_prefix"
        />
      
      )
    } else {
      return ""
    }
  }
  const getLocationFilter = () => {
    if (showFilter("location")) {
      return (
        <SearchAutocomplete
          formInstance={formInstance}
          icon="MdPlace"
          index="location"
          componentId="searchLocation"
          label="Location"
          placeholder="Location"
          fieldName="location"
          dataField="name"
          strictSelection={true}
          value={defaultValues.location}
          showFilter={false}
          allowMultiple={false}
        />
      )
    } else {
      return ""
    }
  }

  const getPeopleFilter = () => {
    if (showFilter("people")) {
      return (
        <SearchAutocomplete
          formInstance={formInstance}
          index="people"
          componentId="searchPeople"
          label="People"
          placeholder="People"
          fieldName="peopleTags"
          dataField="name"
          strictSelection={true}
          value={defaultValues.location}
          showFilter={false}
          allowMultiple={false}
        />
      )
    } else {
      return ""
    }
  }

  const getCopyrightFilter = () => {
    if (showFilter("copyright")) {
      return (
        <div className={styles.container}>
          <div className={styles.title}>Copyright</div>
          <SearchCheckboxes
            formInstance={formInstance}
            componentId="searchCopyright"
            options={[
              { label: "Anyone", name: "ANYONE" },
              { label: "Personal", name: "PERSONAL" },
              { label: "Attribute", name: "ATTRIBUTE" },
            ]}
            fieldName="copyright"
            showFilter={false}
          />
        </div>
      )
    } else {
      return ""
    }
  }

  const getSourceFilter = () => {
    if (showFilter("source")) {
      return (
        <div className={styles.container}>
          <div className={styles.title}>Source</div>
          <SearchCheckboxes
            formInstance={formInstance}
            componentId="searchSource"
            options={[
              { label: "Include AFPA Content", name: "AFPA" },
              { label: "Include FHAA Content", name: "FHAA" },
            ]}
            fieldName="source"
            showFilter={false}
          />
        </div>
      )
    } else {
      return ""
    }
  }

  return (
    <div
      className={classNames(
        simpleVerticalSearch ? styles.verticalWrapper : styles.wrapper,
        "filter-files"
      )}
    >
      {!hideFilters && getHeader()}
      <Form>
        {getTypeFilter()}
        {getKeywordsFilter()}
        {getTitleFilter()}
        {/* These two are grouped in one container while all the others have their own (which is why the weird check is here... Do we need it like that?) */}
        {showFilter("date") || showFilter("location" || showFilter("people")) ? (
          <div>
            {getLocationFilter()}
            {getPeopleFilter()}
            {getDateFilter()}
          </div>
        ) : (
          <></>
        )}
        {getCopyrightFilter()}
        {getSourceFilter()}
        {getRefresher()}
      </Form>
    </div>
  )
}

FilterFiles.defaultProps = {
  simpleVerticalSearch: false,
}

FilterFiles.propTypes = {
  simpleVerticalSearch: PropTypes.bool,
  onClearHandler: PropTypes.func,
  location: PropTypes.object,
}

export default FilterFiles
