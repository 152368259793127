import { convertFromRaw } from "draft-js"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { useForm } from "react-form"
import auth from "../../../services/auth"
import moderationService from "../../../services/moderation"
import Button from "../../common/button/button"
import StoryEditor from "../../common/form/story-editor/story-editor"
import GenericMessage from "../../common/generic-message/generic-message"
import ModerateMediaButtons from "../../media-output/sections/moderate-media-buttons/moderate-media-buttons"
import MediaKeywords from "../sections/media-keywords/media-keywords"
import styles from "./story-details-view.module.scss"
import util from "../../../services/util"
import modal from "../../../services/modal"

const StoryDetailsView = ({
  item,
  setEditStateFn,
  message,
  messageType,
  groupDisplay,
  modal,
  ...props
}) => {
  const [_message, setMessage] = useState(message)
  const [_messageType, setMessageType] = useState(messageType)
  const [mediaDeleted, setMediaDeleted] = useState(false)

  console.log('View Story')

  useEffect(() => {
    setMessage(message)
    setMessageType(messageType)
  }, [message, messageType])

  if (item.content) {
    item.viewContent = convertFromRaw(JSON.parse(item.content))
  } else {
    item.viewContent = null
  }

  const onEditClick = () => {
    setEditStateFn(true)
  }

  const onPublishClick = () => {
    setEditStateFn(false, null, false, true)
  }

  const onDeleteClick = () => {
    modal.setAlertModalOpen(true, {
      title: "This story will be permanently deleted.",
      content: "Do you want to continue?",
      confirm: onDeleteConfirm,
      confirmLabel: "Yes",
    })
  }

  const onDeleteConfirm = () => {
    setEditStateFn(false, null, true).then(deleted => {
      if (deleted) {
        setMediaDeleted(true)
      }
    })
  }

  const onSubmitCorrectionClick = () => {
    moderationService.submitCorrection(item)
  }

  const onFlagInappropriateClick = () => {
    moderationService.flagInappropriate(item)
  }

  const getEditedBy = () => {
    if (item.editedBy && item.canEdit) {
      let label = item.editedByFull
      if (!label) {
        label = util.parseUserToLabel(item.editedBy)
      }

      return (
        <li>
          <span className={styles.attribute}>Edited By:</span>
          <span>{label}</span>
        </li>
      )
    } else {
      return ""
    }
  }

  const getBranch = () => {
    if (item.branch) {
      let label = item.branch.name

      return (
        <li>
          <span className={styles.attribute}>Branch:</span>
          <span>{label}</span>
        </li>
      )
    } else {
      return ""
    }
  }

  const getUploadedBy = () => {
    if (item.canEdit) {
      return (
        <li>
          <span className={styles.attribute}>Author:</span>
          <span>
            {item.uploadedByFull ? item.uploadedByFull : item.uploadedBy._id}
          </span>
        </li>
      )
    } else {
      return "";
    }
  }

  const getPublishButton = () => {
    console.log('Status:', item.status)
    if (
      item.status &&
      item.status.toLowerCase() === "draft" &&
      item.canEdit === true
    ) {
      return (
        <Button
          id="publish-story"
          type="button"
          label="Publish"
          btnSize="small"
          btnStyle="primary"
          fullWidth
          onClick={onPublishClick}
          className={styles.mainButton}
        />
      )
    }
  }

  const handleModerationProgress = moderationMessage => {
    setMessage(moderationMessage)
    setMessageType("info")
  }

  const handleModerationApproved = (moderationMessage, success) => {
    setMessage(moderationMessage)
    if (success) {
      setMessageType("success")
    } else {
      setMessageType("error")
    }
  }

  const handleModerationRejected = (moderationMessage, success) => {
    setMessage(moderationMessage)
    if (success) {
      setMessageType("success")
      setMediaDeleted(true)
    } else {
      setMessageType("error")
    }
  }

  const getModerationButtons = () => {
    // isModerator only returns true if moderation is enabled and the logged in user has authority to moderate
    if (
      (item.status === "PENDING" ||
      item.status === "DELETED") && auth.isModerator()
    ) {
      return (
        <ModerateMediaButtons
          media={item}
          onModerationProgress={handleModerationProgress}
          onModerationApproved={handleModerationApproved}
          onModerationRejected={handleModerationRejected}
        ></ModerateMediaButtons>
      )
    }
  }

  const getEditButton = () => {
    if (item.canEdit) {
      return (
        <Button
          id="edit-story"
          label="Edit"
          btnStyle="secondary"
          btnSize="small"
          fullWidth
          prependIcon="MdEdit"
          onClick={onEditClick}
        />
      )
    }
  }

  const getDeleteButton = () => {
    if (item.canDelete) {
      return (
        <Button
          id="delete-story"
          label="Delete"
          btnStyle="secondary"
          btnSize="small"
          fullWidth
          prependIcon="MdDelete"
          onClick={onDeleteClick}
        />
      )
    }
  }

  const getActions = () => {
    if (mediaDeleted) {
      return <div className={styles.actions}></div>
    } else {
      return (
        <div className={styles.actions}>
          <div className={styles.userActions}>
            {getEditButton()}
            {getDeleteButton()}
            {getPublishButton()}
          </div>
          <div className={styles.adminActions}> {getModerationButtons()}</div>
        </div>
      )
    }
  }

  const defaultValues = React.useMemo(() => item, [])
  const { Form } = useForm({
    defaultValues: defaultValues,
    debugForm: false,
    onSubmit: values => {},
  })

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {modal && <h4>{item.title}</h4>}
        <GenericMessage
          message={_message}
          type={_messageType ? messageType : "success"}
          closeIcon
          setTime
        />
        {!groupDisplay && getActions()}

        <Form className={styles.article}>
          <StoryEditor readOnly={true} field="viewContent" />
        </Form>

        <div className={styles.attributes}>
          <ul className={styles.metadata}>
            <h5>Information</h5>
            {getUploadedBy()}
            {getBranch()}
            <li>
              <span className={styles.attribute}>Posted Date:</span>
              <span>
                {moment(item.dateUploaded)
                  .format("MMM DD, YYYY")
                  .toString()}
              </span>
            </li>
            {getEditedBy()}
            <li>
              <span className={styles.attribute}>Location:</span>
              <span>{item.location}</span>
            </li>
            <li>
              <span className={styles.attribute}>Notes:</span>
              <span>{item.description}</span>
            </li>
            <li>
              <span className={styles.attribute}>People:</span>
              <span>{item.people}</span>
            </li>
          </ul>
          <div className={styles.keywords}>
            <h5>Associated Keywords</h5>
           
            <MediaKeywords keywords={item.keywords}></MediaKeywords>
          </div>
        </div>

        <div className={styles.corrections}>
          <a id="submit-correction" onClick={onSubmitCorrectionClick}>
            Submit Correction
          </a>
          <a id="flag-inappropriate" onClick={onFlagInappropriateClick}>
            Flag as Inappropriate
          </a>
        </div>
      </div>
    </div>
  )
}

StoryDetailsView.propTypes = {
  item: PropTypes.object.isRequired,
  message: PropTypes.string,
  setEditStateFn: PropTypes.func.isRequired,
}

export default StoryDetailsView
