import React, { useState, useEffect, useContext } from "react"
import Autocomplete from "../../inputs/autocomplete/autocomplete"
import asSearchFilter from "../as-search-filter/as-search-filter"
import { LocationContext } from "../../../common/location-provider/location-provider"
import _ from "lodash"
import { useField } from "react-form"

const SearchAutocomplete = ({
  value,
  setValue,
  fieldName,
  fieldOptions,
  label,
  placeholder,
  index,
  dataField,
  clearOnSelect,
  allowMultiple,
  componentid,
  icon,
  strictSelection,
}) => {
  const state = useContext(LocationContext)
  const [values, setValues] = useState(value)
  const fieldInstance = useField(fieldName, fieldOptions)

  useEffect(() => updateQuery(), [value])
  useEffect(() => handleState(), [state])

  const updateQuery = () => {
    if ( getIsStrict() ) {
      const safeValue = value || []
      const newValue = _.sortBy([..._.uniqBy(safeValue, "value")], ["value"])
      // handles search field change
      if (
        (values || []).length !== newValue.length ||
        newValue.some(
          v => (values || []).find(v2 => v.value === v2.value) === undefined
        )
      ) {
        console.log("updateQuery")
        console.log("values: ", values)
        console.log("newValue", newValue)
        console.log(
          "(values || []).length !== newValue.length",
          (values || []).length !== newValue.length
        )
        console.log(
          "newValue.some(v => values.find(v2 => v.value === v2.value) === undefined)",
          newValue.some(
            v => (values || []).find(v2 => v.value === v2.value) === undefined
          )
        )
        setValues(newValue)
        setValue(newValue)
  
        if ((values && values.length > 0 && !newValue) || newValue.length === 0) {
          updateFieldInstance({ value: "" })
        }
      }  
    } else {
      if ( value ) {
        setValue(value);
        updateFieldInstance(value);
      }
    }
  }

  // we need to set this any time the value is changed so that the searchState stays up to date
  const updateFieldInstance = (value) => {
    console.log("Updating search-autocomplete field instance to: " + value, value);
    fieldInstance.setValue(value);
  }

  const handleState = () => {
    if (!state) {
      return
    }
    let stateField = fieldName
    let safeState = []
    if (state.useComponentId) {
      stateField = componentid
    }
    if ( getIsStrict() ) {
      if (state && state[stateField]) {
        let val = state[stateField]
        safeState = val.value ? val.value : val
        if (!_.isArray(safeState)) {
          safeState = []
        }
      }
      const safeValue = value || []
      const newValue = _.sortBy(
        [..._.uniqBy([...safeValue, ...safeState], "value")],
        ["value"]
      )
      // handles search field change
      if (
        (values || []).length !== newValue.length ||
        newValue.some(
          v => (values || []).find(v2 => v.value === v2.value) === undefined
        )
      ) {
        console.log("handleState")
        console.log("values: ", values)
        console.log("newValue", newValue)
        console.log(
          "(values || []).length !== newValue.length",
          (values || []).length !== newValue.length
        )
        console.log(
          "newValue.some(v => values.find(v2 => v.value === v2.value) === undefined)",
          newValue.some(
            v => (values || []).find(v2 => v.value === v2.value) === undefined
          )
        )
        setValues(newValue)
        setValue(newValue)
      }

      // This might cause issues in new use cases (e.g. when multiple values are selected but the latests one is the only one that appears in the input?)
      if (newValue && newValue.length > 0 && fieldInstance.value != newValue[0]) {
        updateFieldInstance(newValue[0])
      }
    
    } else {
      value = state[stateField];
      if ( value ) {
        setValue(value);
        updateFieldInstance(value);
      }
    }
  }

  const handleChange = v => {
    if ( getIsStrict() ) {
      const safeValue = value || []
      const newValue = _.sortBy(
        [..._.uniqBy([...safeValue, v], "value")],
        ["value"]
      )
      if (v === null || v.value === null) {
        // user has cleared the current value
        if (!allowMultiple) {
          newValue.length = 0
          setValues(newValue)
          setValue(newValue)
        } else {
          console.log("Error: attempting to incorrectly clear multi selected")
        }
      }
      // handles search field change
      else if (
        (values || []).length !== newValue.length ||
        newValue.some(
          v => (values || []).find(v2 => v.value === v2.value) === undefined
        )
      ) {
        console.log("handleChange")
        console.log("values: ", values)
        console.log("newValue", newValue)
        console.log(
          "(values || []).length !== newValue.length",
          (values || []).length !== newValue.length
        )
        console.log(
          "newValue.some(v => values.find(v2 => v.value === v2.value) === undefined)",
          newValue.some(
            v => (values || []).find(v2 => v.value === v2.value) === undefined
          )
        )
        setValues(newValue)
        setValue(newValue)
      }  
    } else {
      setValue(v)
    }
  }

  const getIsStrict = () => {
    // defaults to true. Return false only if explicitly stated.
    return strictSelection === undefined || strictSelection === null
      ? true
      : strictSelection
  }

  return (
    <Autocomplete
      index={index}
      label={label}
      placeholder={placeholder}
      field={fieldName}
      dataField={dataField}
      onChange={handleChange}
      clearOnSelect={clearOnSelect}
      showClearButton={true}
      allowMultiple={allowMultiple}
      strictSelection={strictSelection}
      icon={icon}
    />
  )
}

export default asSearchFilter(SearchAutocomplete, "and")
