import PropTypes from "prop-types"
import React from "react"
import axios from "axios"
import auth from "../../../../services/auth"
import classNames from "classnames"
import { MdFileDownload } from "react-icons/md"
import styles from "./download-media-button.module.scss"
import Button from "../../../common/button/button"

const DownloadMediaButton = ({ mediaId, name, className, format }) => {
  const downloadUrl = () =>
    axios.defaults.baseURL + "download/" + mediaId + "/" + name

  const onDownloadClick = event => {
    event.stopPropagation()
    // TODO trigger server download with proper headers instead of this as the browser will just try to open in a new window sometimes
    // see https://stackoverflow.com/questions/50694881/how-to-download-file-in-react-js
    // and https://stackoverflow.com/questions/20508788/do-i-need-content-type-application-octet-stream-for-file-download/20509354#20509354

    console.log("download media click: " + mediaId)
    //axios.get("/download/" + mediaId + "/" + name).then(response => {
    const downloadUrl =
      axios.defaults.baseURL + "download/" + mediaId + "/" + name
    console.log("downloadUrl: ", downloadUrl)

    const link = document.createElement("a")
    link.target = "_blank"
    link.download = ""
    link.href = downloadUrl
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    //})
  }

  if (format === "compact") {
    return (
      <form method="get" action={downloadUrl()} target="_blank">
        <button
          type="submit"
          onClick={e => e.stopPropagation()}
          className={classNames(styles.wrapper, className)}
          id={"download-media" + (mediaId ? "-" + mediaId : "")}
        >
          <MdFileDownload />
        </button>
      </form>
    )
  } else {
    return (
      <form method="get" action={downloadUrl()} target="_blank">
        <div className={className}>
          <Button
            label="Download"
            btnSize="large"
            type="submit"
            fullWidth
            prependIcon="MdFileDownload"
            onClick={() => {}}
            id={"download-media" + (mediaId ? "-" + mediaId : "")}
          />
        </div>
      </form>
    )
  }
}

DownloadMediaButton.defaultProps = {
  format: "compact",
}

DownloadMediaButton.propTypes = {
  mediaId: PropTypes.string.isRequired,
  format: PropTypes.string, // standard or compact?
  className: PropTypes.string,
}

export default DownloadMediaButton
