import _ from "lodash"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { useForm } from "react-form"
import { MdHelpOutline } from "react-icons/md"
import useSuggestedKeywords from "../../../hooks/use-suggested-keywords"
import usePeople from "../../../hooks/use-people-tags"
import constants from "../../../services/constants_fhaa"
import util from "../../../services/util"
import keywordService from "../../../services/keyword"
import peopleService from "../../../services/people"
import Button from "../../common/button/button"
import ModalWindow from "../../common/modal-window/modal-window"
import MediaMapping from "../../media-mapping/media-mapping"
import CollapsibleListSection from "../../common/collapsible-list-section/collapsible-list-section"
import Checkbox from "../../common/form/check-box/check-box"
import RadioButtonList from "../../common/form/radio-button-list/radio-button-list"
import TextArea from "../../common/form/text-area/text-area"
import SelectBox from "../../common/form/select-box/select-box"
import TextField from "../../common/form/text-field/text-field"
import YearMonth from "../../common/form/year-month/year-month"
import GenericMessage from "../../common/generic-message/generic-message"
import SuggestedKeywords from "../../common/suggested-keywords/suggested-keywords"
import AutocompleteTags from "../../search/inputs/autocomplete-tags/autocomplete-tags"
import Autocomplete from "../../search/inputs/autocomplete/autocomplete"
import DownloadMediaButton from "../sections/download-media-button/download-media-button"
import styles from "./media-details-edit.module.scss"
import ReactTooltip from "react-tooltip"
import { getGroups } from "../../../services/group"
import CreateMedia from "../../create-group"

const permissionOpts = constants.getPermissionOptions()
const copyrightOpts = constants.getCopyrightOptions()

const MediaDetailsEdit = ({
  item,
  setEditStateFn,
  itemTags,
  itemPeopleTags,
  itemGroups,
  message,
  messageType,
  ...props
}) => {
  const [submitAttempted, setSubmitAttempted] = useState()
  const [suggestedKeywords, updateKeywords] = useSuggestedKeywords()
  const [_messageType, setMessageType] = useState(messageType)
  const [_message, setMessage] = useState(message)
  const [peopleTags, updatePeople] = usePeople()
  const [mapModal, setMapModal] = useState(false)
  const [geo, setGeo] = useState()
  const [location, setLocation] = useState(item["location"])
  const [group, setGroup] = useState([])
  const [checkedItems, setCheckedItems] = useState([])
  const [searchTerm, setSearchTerm] = React.useState("")
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [groupWord, setGroupWord] = useState([])
  const [groupsSelected, setGroupsSelected] = useState([])
  const [allGroups, setAllGroups] = useState([])
  const [personName, setPersonName] = useState([]);


  useEffect(() => {
    setMessage(message)
    setMessageType(messageType)
  }, [message, messageType])

  useEffect(() => {
    if (item["geo"]) {
      setGeo(item["geo"])
    }
    else {
      setGeo({lat: 55.000000, lon: -115.000000})
    }
    
    let all = []  
    getGroups().then(groups => {
      _.map(groups, (group) => {
        const selectGroup = {
          label: group.title,
          value: group.title,
          ...group
        }   

        all.push(selectGroup)
      })

      setAllGroups(all)
    })

    let selectedGroups = []
    _.map(itemGroups, (group) => {
        const selectedGroup = {
          label: group.title,
          value: group.title,
          ...group
        } 

        selectedGroups.push(selectedGroup)
    })  

    setGroupsSelected(selectedGroups)    
  }, [])

  // store a reference of the keywords on the item as "tag" objects.
  item.tags = itemTags
  item.peopleTags = itemPeopleTags
  !item.description ? (item.description = "") : ""
  
  const defaultValues = React.useMemo(() => item, [])

  const formInstance = useForm({
    defaultValues: defaultValues,
    debugForm: false,
    onSubmit: values => {
      var updatedObj = null
      if (meta.isTouched && meta.isValid) {

        let lat = ''
        let lon = ''
        if (geo.lat !== '') {
          lat = geo.lat
          lon = geo.lon
        }

        updatedObj = {
          ...values,
          // location: getLocation(values),
          location: location,
          geo: {lat, lon},
          groups: groupsSelected,
          keywords: keywordService.getKeywordsForSave([values], ["tags"]),
          peopleTags: peopleService.getPeopleForSave([values], ["peopleTags"])
        }
      }
      setEditStateFn(false, updatedObj)
    },
    validate: (values, instance) => {
      setSubmitAttempted(false)
      if (!values.location || values.location.value == "") {
        return true //  "Location is required."
      } else if (!values.permissions) {
        return true //  "Sharing permission is required."
      } else if (!values.copyright) {
        return true //  "Copyright permission is required."
      } else if (!values.title) {
        return true //  "Title is required"
      } else if (!values.photoDate) {
        return true //  "Photo Date is required"
      } else if (!values.tags || values.tags.length === 0) {
        // we look at "tags" as that is the current tags from editing. "keywords" gets updated during submit
        return true //  "At least one keyword is required"
      }

      // return false
    },
  })

  const handleChangeGroup = (value) => {
      setGroupsSelected(value)
  }

  const { Form, meta, pushFieldValue } = formInstance

  if (formInstance.values.isDateUnknown) {
    formInstance.values.photoDate= ''
  } 

  const formValues = formInstance.values

  const GroupItem = checkedItems => {
    setCheckedItems(checkedItems)
    formValues.groups = checkedItems
  }

  formValues.groups = checkedItems

  const setAddedGroup = (addedGroup) => {
    const added = {
      ...addedGroup,
      label: addedGroup.title,
      value: addedGroup.title
    }

    const selected = [
      ...groupsSelected,
      added
    ]

    setGroupsSelected(selected)

    const all = [
      ...allGroups,
      added
    ]
    setAllGroups(all)
  }

  const getLocation = updatedObj => {
    if (updatedObj.location == null || _.isString(updatedObj.location))
      return updatedObj.location
    else {
      return updatedObj.location.value
    }
  }

  const addSuggested = k => {
    const newKeyword = { value: k.term, source: { _id: k._id } }
    pushFieldValue("tags", newKeyword)
  }

  const onSaveClick = () => {
    setSubmitAttempted(true)
  }

  const onCancelClick = () => {
    setEditStateFn(false)
  }

  const CreateGroup = () => {
    setCreateModalOpen(true)
  }

  const getUpdatedGroups = () => {
      getGroups().then(groupWord => {
        // console.log("groupWord:", groupWord)
        // setGroupItems(groupWord)
      })
  }


  const getPermissionAndCopyright = () => {
    return (
      <div className={styles.fieldGroup}>
        <h4>Permissions &amp; Copyright</h4>
        <div className={styles.permissionGroup}>
          <h5>
            Sharing<span className={styles.star}>*</span>
          </h5>
          <RadioButtonList
            field="permissions"
            options={permissionOpts}
            validate={value => {
              return !value ? "This is required!" : false
            }}
          />
        </div>

        <div className={styles.permissionGroup}>
          <h5>
            Copyright
            <span className={styles.star}>*</span>
            <span
              className={styles.helpIcon}
              data-tip="Choose a copyright option that works best for you and your organization."              data-event="click focus"
            >
              <a onClick={e => e.preventDefault()}>
                <MdHelpOutline />
              </a>
            </span>
          </h5>
          <RadioButtonList
            field="copyright"
            options={copyrightOpts}
            validate={value => {
              return !value ? "This is required!" : false
            }}
          />
        </div>
      </div>
    )
  }

  const getEditedBy = () => {
    if (item.editedBy) {
      let label = item.editedByFull
      if (!label) {
        label = util.parseUserToLabel(item.editedBy)
      }

      return (
        <li>
          <span className={styles.attribute}>Edited By:</span>
          <span>{label}</span>
        </li>
      )
    } else {
      return ""
    }
  }

  const getUploadedBy = () => {
    return (
      <li>
        <span className={styles.attribute}>Contributor / Uploader:</span>
        <span>
          {item.uploadedByFull ? item.uploadedByFull : item.uploadedBy}
        </span>
      </li>
    )
  }

  const getActions = () => {
    return (
      <div className={styles.actions}>
        {/* Hidden for now, we can't have this wrapped in a form because it also has a form in it's mark up */}
        {/* <DownloadMediaButton
          format="standard"
          mediaId={item.source_file._id}
          name={item.source_file.name}
          className={styles.mainButton}
        /> */}

        <div className={styles.subButton}>
          <Button
            id="save-media"
            label="Save"
            type="submit"
            btnStyle="secondary"
            btnSize="small"
            fullWidth
            prependIcon="MdSave"
            onClick={onSaveClick}
          />
          <Button
            id="cancel-media"
            label="Cancel"
            btnStyle="secondary"
            btnSize="small"
            fullWidth
            prependIcon="MdCancel"
            onClick={onCancelClick}
          />
        </div>
      </div>
    )
  }

  const getAdditionalDetails = () => {
    return (
      // TODO add all the other fields
      <CollapsibleListSection>
        <li>
          <TextField label="Province" field="province" type="text" />
        </li>
        <li>
          <TextField label="Forest" field="forest" type="text" />
        </li>
        <li>
          <TextField label="Photo Number" field="photoNumber" />
        </li>
        <li>
          <TextField label="Source Number" field="sourceNumber" />
        </li>
        <li>
          <TextField label="Catalogue Number" field="catalogueNumber" />
        </li>
      </CollapsibleListSection>
    )
  }

  const getKeywords = () => {
    ReactTooltip.rebuild()

    return (
      <div className={styles.fieldGroup}>
        <h4>Keywords</h4>
        <div className={styles.keywordWrap}>
          <AutocompleteTags
            index="keyword"
            dataField="term"
            field="tags"
            label="Preferred Term"
            defaultValue={[]}
            validate={tags => {
              updateKeywords(tags)
              return !tags || !tags.length ? "This is required!" : false
            }}
            mandatory
          />

          <h5>
            Suggested Keywords
            <span
              className={styles.helpIcon}
              data-tip="Adding additional keywords will help others find your image during searches."
              data-event="click focus"
            >
              <a onClick={e => e.preventDefault()}>
                <MdHelpOutline />
              </a>
            </span>
          </h5>
          <SuggestedKeywords words={suggestedKeywords} add={addSuggested} />
        </div>

      </div>
    )
  }

  const getGroupMarkup = () => {
    ReactTooltip.rebuild()

    return (
      <div className={styles.fieldGroup}>
        <h4>
          Add to Group
          <span
            styles={{ cursor: "pointor" }}
            className={styles.helpIcon}
            data-tip="Please click CREATE to create a new group."
            data-event="click focus"
          >
            <a onClick={e => e.preventDefault()}>
              <MdHelpOutline />
            </a>
          </span>
        </h4>
        <div style={{ display: "flex" }}>
          <SelectBox
              field="groups"
              options={allGroups}
              value={groupsSelected}
              onChange={handleChangeGroup}
              defaultValue={'Group'}
              isMulti
          />
          {getModalWindow()} 
          <span style={{ marginLeft: "5px" }}>
            {
              <Button
                id="add-group"
                label="New Group"
                type="button"
                btnStyle="primary"
                btnSize="multipleWords"
                onClick={CreateGroup}
              />
            }
          </span> 
        </div>
      </div>   
    )
  }

  const getModalWindow = () => {
    const onModalClose = () => {
      setCreateModalOpen(false)
    }
    if (createModalOpen) {
      return (
        <ModalWindow title="Create Group" closeHandler={onModalClose}>
          <CreateMedia
            updatedGroups={getUpdatedGroups}
            setCreateModalOpen={setCreateModalOpen}
            setGroup={setGroup}
            group={group}
            setCheckedItems={GroupItem}
            setGroupWord={setGroupWord}
            setAddedGroup={setAddedGroup}
          />
        </ModalWindow>
      )
    } else {
      return ""
    }
  }

  const getPeople = () => {
    ReactTooltip.rebuild()

    return (
      <div className={styles.fieldGroup}>
        <h4>Tag People</h4>
        <div className={styles.keywordWrap}>
          <AutocompleteTags
            index="people"
            dataField="name"
            field="peopleTags"
            label="People"
            defaultValue={[]}
            validate={peopleTags => {
              updatePeople(peopleTags)
            }}
          />
        </div>  
      </div>
    )
  }

  const setLocationCoordinates = (location) => {
    
    const obj = {
      name: location.name,
      lat: location.geo.lat,
      lon: location.geo.lon
    }

    setGeo(obj)
    setLocation(location.name)
  }

  const onMapClick = () => {
    setMapModal(true)

    // addMappingMarker(geo)
  }

  const onClearClick = () => {
    setGeo({lat: 55.000000, lon: -115.000000})
    setLocation('')
  }


  const getMapModal = () => {
    const onMapClose = () => {
      setMapModal(false)
    }

    if (mapModal) {
      return (
        <ModalWindow title="Map Position" closeHandler={onMapClose}>
          <MediaMapping 
            setLocation={setLocationCoordinates} 
            setMapModal={setMapModal}
            location={location}
            geo={geo}
            create={false}
          />
        </ModalWindow>
      )
    }
  }

  return (
    <>
      <Form className={styles.wrapper}>
        <div className={styles.container}>
          <GenericMessage
            message={_message}
            type={_messageType ? messageType : "info"}
            closeIcon
          />
          <div className={styles.details}>
            <div className={styles.detailLeft}>
              <h4>Update Media Information</h4>
              <ul className={styles.metadata}>
                <li>
                  <TextField
                    label="Title"
                    field="title"
                    validate={value => {
                      return !value ? "This is required!" : false
                    }}
                    mandatory
                  />
                </li>
                <li>
                  <TextArea label="Note" field="description" />
                </li>
                <li>
                  <YearMonth
                    showDay={true}
                    label="Photo Date"
                    field="photoDate"
                    dateUnknown={formInstance.values.isDateUnknown}
                    disabled={formInstance.values.isDateUnknown}
                    validate={value => {
                      return !value && !formInstance.values.isDateUnknown ? "This is required!" : false
                    }}
                    mandatory={!formInstance.values.isDateUnknown}
                  />
                </li>
                <li>
                  <Checkbox
                    label="Date is Approximate"
                    field="isDateApproximate"
                    isDisabled={formInstance.values.isDateUnknown}
                  />
                  <Checkbox
                    label="Unknown Date"
                    field="isDateUnknown"
                  />
                </li>
                {/* <li>
                  <Autocomplete
                    label="Location"
                    index="location"
                    field="location"
                    curText={item["location"]}
                    dataField="name"
                    icon="MdPlace"
                    allowMultiple={false}
                    mandatory
                    validate={value => {
                      return !value || value.value == ""
                        ? "This is required!"
                        : false
                    }}
                  />
                </li> */}
                <li>
                  <div className={styles.locationSection}>
                    <div className={styles.locationItem}>
                        {'Location: '}
                        <b>{location ? location : 'No Location'}</b>
                    </div>
                    <div className={styles.locationItem}>
                      <Button
                        id="map-button"
                        type="button"
                        btnStyle="primary"
                        btnSize="small"
                        prependIcon="MdGpsFixed"
                        onClick={onMapClick}
                      />
                    </div>  
                    <Button
                      id="clear-button"
                      type="button"
                      btnStyle="primary"
                      btnSize="small"
                      prependIcon="MdDeleteForever"
                      onClick={onClearClick}
                    />
                  </div>
                  {getMapModal()}
                </li>  
                <li>
                  <TextField
                    label="Collection Name"
                    field="collectionName"
                    type="text"
                    description="The collection this media belongs to"
                  />
                </li>
                <li>
                  <TextField
                    label="Credit"
                    field="credit"
                    type="text"
                    description="The person who provides this media."
                  />
                </li>
                <li>
                  <TextField
                    label="Photographer"
                    field="photographer"
                    type="text"
                    description="The person who captured this media."
                  />
                </li>
                {getAdditionalDetails()}
                {getUploadedBy()}
                <li>
                  <span className={styles.attribute}>Uploaded Date:</span>
                  <span>
                    {moment(item.dateUploaded)
                      .format("MMM DD, YYYY")
                      .toString()}
                  </span>
                </li>
                {getEditedBy()}
              </ul>
            </div>
            <div className={styles.detailRight}>
              {getActions()}
              {getPermissionAndCopyright()}
              {getKeywords()}
              {getGroupMarkup()}
              {getPeople()}
            </div>
          </div>
        </div>
      </Form>
    </>
  )
}

MediaDetailsEdit.defaultProps = {
  itemTags: [],
}

MediaDetailsEdit.propTypes = {
  item: PropTypes.object.isRequired,
  itemTags: PropTypes.array,
  message: PropTypes.string,
  setEditStateFn: PropTypes.func.isRequired,
}

export default MediaDetailsEdit
