import { ReactiveList } from "@appbaseio/reactivesearch"
import PropTypes from "prop-types"
import classNames from "classnames"
import React, { useState } from "react"
import StandardCard from "../../cards/standard-card/standard-card"
import styles from "./list-media.module.scss"
import Empty from "../../../common/empty/empty"
import util from "../../../../services/util"
import { FaTrashAlt, FaSpinner } from "react-icons/fa"
import "font-awesome/css/font-awesome.min.css"

// const baseUrl = process.env.GATSBY_FEDIA_DMS_PORT && process.env.GATSBY_FEDIA_DMS_PORT !== '80' && process.env.GATSBY_FEDIA_DMS_PORT !== 80 ? 
//   `https://${process.env.GATSBY_FEDIA_DMS_HOST}:${process.env.GATSBY_FEDIA_DMS_PORT}/` :
//   `http://${process.env.GATSBY_FEDIA_DMS_HOST}/`;

// const fixUrls = media => {
//   return {
//     ...media,
//     webViewUrl: media.webViewUrl ? `${baseUrl}${media.webViewUrl.replace('http://AppSt-Fedia-PQ7U6WD4330R-317694051.ca-central-1.elb.amazonaws.com/','')}` : media.webViewUrl,
//     thumbnailUrl: media.thumbnailUrl ? `${baseUrl}${media.thumbnailUrl.replace('http://AppSt-Fedia-PQ7U6WD4330R-317694051.ca-central-1.elb.amazonaws.com/','')}` : media.thumbnailUrl
//   };
// }

const ListMedia = ({ onMediaNavigate, onSelectKeyword, setData, showTiles, fnSetLastRefreshDate }) => {
  const [loadingMarkers, setLoadingMarkers] = useState(true)
  const [dataFound, setDataFound] = useState(false)
  const [mediaPlayingId, setMediaPlayingId] = useState('')
  const [mediaPlaying, setMediaPlaying] = useState(false)
  const [lastRefreshTime, setLastRefreshTime] = useState()  

  const setLoading = () => {
    // console.log('test')
    // setLoadingMarkers(false)
  }

  const fnSetMediaPlayingId = (id) => {
    console.log('Media Playing Id ', id)
    setMediaPlayingId(id)
  }

  const fnSetMediaPlaying = (playing) => {
    setMediaPlaying(playing)
  }


  const renderStandardCards = data => {

    if (data.length > 0) {
      setLoadingMarkers(false)
    }
    else {
      setLoadingMarkers(true)
    }  

    return (
      <div>
        {loadingMarkers &&
          <div> 
            <FaSpinner className={classNames('fa-spin', styles.spinner)}/>
          </div>  
        }
        <br />  
        <div className={data.length < 5 ? styles.cardLess : styles.cards}>
          {data.map(item => (
            <StandardCard
              key={item._id}
              data={util.fixUrls(item)}
              onMediaNavigate={onMediaNavigate}
              onSelectKeyword={onSelectKeyword}
              mediaPlayingId={mediaPlayingId}
              fnSetMediaPlayingId={fnSetMediaPlayingId}
              mediaPlaying={mediaPlaying}
              fnSetMediaPlaying={fnSetMediaPlaying}
              fnSetLastRefreshDate={fnSetLastRefreshDate}
              className={data.length < 5 ? styles.cardItem : ""}
            />
          ))}
        </div>
      </div>   
    )
  }

  return (
    <div className={styles.container}>
      <ReactiveList
        className={styles.wrap}
        componentId="SearchResult"
        dataField="name"
        title="ReactiveList"
        pagination={true}
        from={0}
        size={50}
        react={{
          and: {
            and: [
              "searchKeywords",
              "searchTitle",
              "searchLocation",
              "searchType",
              "searchSource",
              "searchCopyright",
              "searchCaptureDate",
              "searchMulti",
              "searchDateUnknown",
              "searchPeople",
              "refresher"
              // "searchSpecies",
              // "searchArchived",
            ],
          },
        }}
        onData={(data) => {
          setData(data)
        }}
        showLoader={false}
        render={({ Loading, error, data }) => {
          setDataFound(false)
          if (Loading) {
            return <div>Fetching Result.</div>
          }
          if (error) {
            return (
              <div>
                Something went wrong! Error details {JSON.stringify(error)}
              </div>
            )
          }
          return renderStandardCards(data)
          console.log('renderStandardCards')
          // this wrapper div is used for a test but not a very important one and I don't want to break any styling
          // return (<div id="standardCards">{renderStandardCards(data)}</div>)
        }}
        showResultStats={false}
        renderNoResults={function() {
          return (
            <Empty
              title="No results found"
              content="It seems we can’t find any results based on your search."
            />
          )
        }}
      />
    </div>
  )
}

ListMedia.propTypes = {
  onMediaNavigate: PropTypes.func,
  onSelectKeyword: PropTypes.func,
}

export default ListMedia
