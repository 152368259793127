import { SelectedFilters, StateProvider } from "@appbaseio/reactivesearch"
import classNames from "classnames"
import moment from "moment"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import React, { useState, useEffect } from "react"
import { FaArrowLeft } from "react-icons/fa"
import { MdClose } from "react-icons/md"
import useSuggestedKeywords from "../../../hooks/use-suggested-keywords"
import FilterFiles from "../../search/filter-files/filter-files"
import styles from "./search-group-content.module.scss"
import _ from "lodash"
import { useForm } from "react-form"
import Button from "../../common/button/button"
import modal from "../../../services/modal"
import RenderMedia from "../../common/render-media/render-media"
import { getGroups, getGroup, deleteGroups } from "../../../services/group"
import "react-notifications/lib/notifications.css"
import { MdKeyboardArrowLeft } from "react-icons/md"
import auth from "../../../services/auth"
import { getDialogActionsUtilityClass, getDialogContentTextUtilityClass } from "@mui/material"

const SearchGroupContent = ({ item, heading, gid, fnEditState, editFlag, fnEditFlag, lastRefreshTime }) => {
  const propsWrapper = { props: null }
  const [isOpen, toggleOpen] = useState(false)
  const [groups, setGroups] = useState([])
  const [group, setGroup] = useState({})
  const [medias, setMedias] = useState([])
  const [media, setMedia] = useState([])
  const [suggestedKeywords, updateKeywords] = useSuggestedKeywords()
  const [lastMultiValue, setLastMultiValue] = useState(null)
  const searchFns = {}
  let _searchState = {}
  const [activeFilters, setActiveFilters] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)

  const authObj = auth.getAuth()

  const getFilterOptionsMarkUp = () => {
    return (
      <div className={styles.closeBtn}>
          <FilterFiles lastRefreshTime={lastRefreshTime}/>
      </div>
    )
  }
  const useContext = React.createContext()
  const { data, editData } = useContext

  useEffect(() => {
    getGroup(gid).then(group => {
      setGroup(group)
    })
  }, [])

  if (editFlag) {
    console.log('Edit Flag')
    getGroup(gid).then(group => {
      setGroup(group)
      fnEditFlag(false)
    })    
  }

  const formInstance = useForm({
    debugForm: false,
  })

  const onReject = event => {
    event.stopPropagation()

    modal.setAlertModalOpen(true, {
      title: `Group ${group.title} will be permanently deleted. Media will not be deleted.`,
      content: "Do you want to continue?",
      confirm: onModalConfirm,
      confirmLabel: "Yes",
    })
  }

  const onModalConfirm = () => {
    console.log("delete Media")
    deleteGroups(gid).then(group => {
      console.log("deleted", group)
      setGroup(group)
    })
    navigate(`/view-my-media`)
  }

  const { Form, meta, pushFieldValue } = formInstance

  const onEditClick = () => {
    fnEditState()
  }

  const onMediaAttached = attachedMedia => {
    var addedMedias = []
    addedMedias.push(attachedMedia)
    const NewMedias = _.union(media, addedMedias)
    console.log("NewMedia", NewMedias)
    setMedia(NewMedias)
    setModalOpen(false)
  }

  const onBackClick = async () => {
    navigate(`/app/detailed-file/${mediaType}/${mediaId}`, {
      state: {
        // path: window.location.pathname,
      },
    })
  }

  // TODO this could maybe be it's own component?
  const getActiveFiltersMarkup = () => {
    return (
      <Form>
        <div className={styles.keywords}>
          <div className={styles.selected}>
                <div className={styles.hits}>
                  <br />
                  <br />
                  <div>
                    <div
                      className="group-title"
                      styles={{ fontWeight: "100px" }}
                    >
                      {group.title}
                    </div>

                    <span
                      className="group-description"
                      styles={{ fontSize: "small" }}
                    >
                      <h6>
                        <b>Description:</b>
                      </h6>
                      <h6>
                        <justify>{group.groupDescription}</justify>
                      </h6>
                    </span>

                    <span
                      className="group-createdAt"
                      styles={{
                        fontSize: "small",
                        display: "flex",
                        marginLeft: "15px",
                      }}
                    >
                      <h6>
                        <b>CreatedAt:</b>
                      </h6>
                      <h6>
                        {moment(group.createdAt)
                          .format("MMM DD, YYYY")
                          .toString()}
                      </h6>
                    </span>
                  </div>

                  <br />
                </div>


            {auth.isAuthorizedUser() || auth.isBranchAdmin() ? (
              <div
                style={{ display: "flex", marginLeft: "100px" }}
              >
                <span>
                  <Button
                    id="edit-group"
                    label="Edit"
                    type="button"
                    prependIcon="MdEdit"
                    btnStyle="secondary"
                    btnSize="medium"
                    onClick={onEditClick}
                  />
                </span>

                <span style={{ marginLeft: "15px" }}>
                  <Button
                    id="delete-group"
                    label="Delete"
                    type="submit"
                    prependIcon="MdDelete"
                    btnStyle="primary"
                    btnSize="medium"
                    onClick={onReject}
                  />
                </span>
              </div>
              ) : (
                ""
            )}
          </div>
        </div>
      </Form>
    )
  }

  const getActiveFiltersMarkupTest = () => {
    return (
      <div className={styles.keywords}>
        <div className={styles.selected}>
          <div>
            <Form>
              <div className={styles.alignbuttons}>
                <div className={styles.hits}>
                  <br />
                  <br />
                  <div>
                    <div
                      className="group-title"
                      styles={{ fontWeight: "100px" }}
                    >
                      {group.title}
                    </div>

                    <span
                      className="group-description"
                      styles={{ fontSize: "small" }}
                    >
                      <h6>
                        <b>Description:</b>
                      </h6>
                      <h6>
                        <justify>{group.groupDescription}</justify>
                      </h6>
                    </span>

                    <span
                      className="group-createdAt"
                      styles={{
                        fontSize: "small",
                        display: "flex",
                        marginLeft: "15px",
                      }}
                    >
                      <h6>
                        <b>CreatedAt:</b>
                      </h6>
                      <h6>
                        {moment(group.createdAt)
                          .format("MMM DD, YYYY")
                          .toString()}
                      </h6>
                    </span>
                  </div>

                  <br />
                </div>
                  {auth.isAuthorizedUser() || auth.isBranchAdmin() ? (
                <div className={styles.btncont}>
                  <Button
                    id="edit-group"
                    label="Edit"
                    type="button"
                    prependIcon="MdEdit"
                    btnStyle="secondary"
                    btnSize="medium"
                    onClick={onEditClick}
                  />
                  <div
                    className="btns"
                    style={{
                      display: "flex",
                      marginTop: "25px",
                      marginLeft: "0px"
                    }}
                  >
                    <span>
                      <Button
                        id="delete-group"
                        label="Delete"
                        type="submit"
                        prependIcon="MdDelete"
                        btnStyle="primary"
                        btnSize="medium"
                        onClick={onReject}
                      />
                    </span>

                  </div>
                </div>
                    ) : (
                      ""
                  )}
              </div>
            </Form>
          </div>
        </div>
      </div>
    )
  }  

  return (
    <Form>
      <div className={classNames(styles.wrapper, isOpen ? styles.fixed : "")}>
        {heading ? <h2>{heading}</h2> : ""}
        <div className={styles.container}>
          {getFilterOptionsMarkUp()}
          <div className={styles.medias}>
            <div className={styles.media}>
              {getActiveFiltersMarkup()}
              {group.title && (
                <RenderMedia
                  onMediaAttached={onMediaAttached}
                  mediaType={"Photo"}
                  group={group.title}
                  groupData={group}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <svg display="none">
        <path
          id="filter-icon"
          d="M18.614,18.786a2.537,2.537,0,0,1-4.8,0H3.357a.857.857,0,0,1,0-1.714H13.814a2.537,2.537,0,0,1,4.8,0h1.029a.857.857,0,0,1,0,1.714Zm-3.257-.857a.857.857,0,1,0,.857-.857.81.81,0,0,0-.857.857Zm-1.457-12a2.537,2.537,0,0,1-4.8,0H3.357A.81.81,0,0,1,2.5,5.071a.81.81,0,0,1,.857-.857H9.1a2.537,2.537,0,0,1,4.8,0h5.743a.81.81,0,0,1,.857.857.81.81,0,0,1-.857.857Zm-3.257-.857a.857.857,0,1,0,1.714,0,.857.857,0,1,0-1.714,0Zm9,7.286H9.186a2.537,2.537,0,0,1-4.8,0H3.357a.857.857,0,1,1,0-1.714H4.386a2.537,2.537,0,0,1,4.8,0H19.643a.857.857,0,1,1,0,1.714ZM5.929,11.5a.81.81,0,0,0,.857.857.857.857,0,1,0,0-1.714.81.81,0,0,0-.857.857Z"
          transform="translate(-1.834 -1.5)"
        />
      </svg>
    </Form>
  )
}

SearchGroupContent.propTypes = {
  setEditStateFn: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
}

export default SearchGroupContent
