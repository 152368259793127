import { navigate } from "gatsby"
import React, { useState } from "react"
import { MdKeyboardArrowLeft } from "react-icons/md"
import Layout from "../../../components/layout/layout/layout"
import constants from "../../../services/constants_fhaa"
import media from "../../../services/media"
import story from "../../../services/story"
import Webfile from "../../common/display/webfile/webfile"
import GenericMessage from "../../common/generic-message/generic-message"
import ViewMedia from "../view-media/view-media"
import ViewStory from "../view-story/view-story"
import styles from "./detailed-file.module.scss"
import _ from "lodash"
import moment from "moment"
import classNames from "classnames"
import util from "../../../services/util"

// const baseUrl = process.env.GATSBY_FEDIA_DMS_PORT && process.env.GATSBY_FEDIA_DMS_PORT !== '80' && process.env.GATSBY_FEDIA_DMS_PORT !== 80 ? 
//   `https://${process.env.GATSBY_FEDIA_DMS_HOST}:${process.env.GATSBY_FEDIA_DMS_PORT}/` :
//   `http://${process.env.GATSBY_FEDIA_DMS_HOST}/`;

const fixUrl = webViewUrl => {
  let baseUrl = ''
  if (webViewUrl.includes('http://')) {
    baseUrl = util.baseUrl()
  }
  else {
    baseUrl = util.baseUrl().replace('http://', '')
  }

  return webViewUrl ? `${util.baseUrl()}${webViewUrl.replace(baseUrl, '').toLowerCase().replace('http://appst-fedia-pq7u6wd4330r-317694051.ca-central-1.elb.amazonaws.com/','')}` : webViewUrl;
}

const DetailedFile = ({ id, type, location, fnSetLastRefreshDate, groupDisplay }) => {
  const [item, setItem] = useState()
  const [message, setMessage] = useState("Loading Media...")
  const [messageType, setMessageType] = useState("info")
  const [showingInvalid, setShowingInvalid] = useState(false)

  // console.log('location:', location)

  // TODO use Router/route to handle and pass this in???
  // var params = location.search
  //   .split("?mediaId=")
  //   .pop()
  //   .split("&mediaType=")
  // if (!params || params.length === 0) {
  //   params = [null, null]
  // }
  // var id = params[0]
  // var type = params[1] ? params[1] : null

  // if (location && location.state && location.state.id) {
  //   id = location.state.id
  // }

  // if (location && location.state && location.state.type) {
  //   type = location.state.type
  // }

  const showInvalidMedia = () => {
    if (!showingInvalid) {
      // prevent re-render loop
      setShowingInvalid(true)
      // there was missing information. Let the user know
      setMessage("Invalid Media")
      setMessageType("error")
      autoNavBack()
    }
  }

  const autoNavBack = () => {
    // there was no media to load. Go back to search
    setTimeout(() => {
      onBackClick()
    }, 5000)
  }

  if (id) {
    if (!item || id !== item._id) {
      if (!type || (type && type.toLowerCase() !== "story")) {
        media.getMedia(id).then(
          media => {
            setItem(media)
          },
          error => {
            setMessage("File not found")
            setMessageType("error")
            autoNavBack()
          }
        )
      } else if (type.toLowerCase() === "story") {
        story.getStory(id).then(
          story => {
            setItem(story)
          },
          error => {
            setMessage("File not found")
            setMessageType("error")
            autoNavBack()
          }
        )
      }
    } else if (!item) {
      showInvalidMedia()
    }
  } else {
    showInvalidMedia()
  }

  const onBackClick = async () => {

    console.log('Location:', location)
    if (location.state && location.state.path) {
      const _searchState =
        // when we have state information we can use it to assist navigation (for preserving previous searches)
        navigate(location.state.path, {
          state: _.extend({ useComponentId: true }, location.state.searchState),
        })
    } else {
      await navigate("/")
    }
  }

  const getHeaderHeroImageWrapper = () => {
    const innerStoryHeader = (
      <>
        <div className={styles.info}>
          <h2 className={styles.title}>{item.title}</h2>
          <p className={styles.subtitle}>
            {moment(item.createdAt)
              .format("MMM DD, YYYY")
              .toString()}
            <span> | </span> By{" "}
            {item.uploadedByUsername
              ? item.uploadedByUsername
              : item.uploadedBy}
          </p>
        </div>
        <span className={styles.mask}></span>
      </>
    )
    console.log('Item Media', item.media)
    if (item && item.media && item.media.length > 0) {
      console.log('Url:', fixUrl(item.media[0].webViewUrl))
      let url =  fixUrl(item.media[0].webViewUrl)
      if (process.env.GATSBY_FEDIA_DMS_HOST === 'api.fedia-test.com' || process.env.GATSBY_FEDIA_DMS_HOST === 'api.fedia-api.com') {
        url = url.replace('localhost:1337/', '')
      }  
      const lastIndex = url.lastIndexOf('/')
      url = url.substring(0, lastIndex + 2)

      return (
        <div
          className={styles.heroImage}
          style={{
            backgroundImage: `url(${url})`,
          }}
        >
          {innerStoryHeader}
        </div>
      )
    } else {
      return <div>{innerStoryHeader}</div>
    }
  }

  const getHeader = () => {
    if (item && item.type.toLowerCase() === "story") {
      return (
        <div className={styles.storyWrap}>
          {/* <div className={styles.container}>
            <div className={styles.back}>
              <a id="return-to-list" onClick={onBackClick}>
                <MdKeyboardArrowLeft /> Go Back
              </a>
            </div>
          </div> */}
          {getHeaderHeroImageWrapper()}
        </div>
      )
    } else {
      // default header for all other media (or when no media)
      return (
        <div className={styles.mediaWrap}>
          <div className={styles.container}>
            {item ? (
              // <div className={styles.back}>
              //   <a id="return-to-list" onClick={onBackClick}>
              //     <MdKeyboardArrowLeft /> Go Back
              //   </a>
              // </div>
              ""
            ) : (
              ""
            )}
            <div
              className={classNames(
                styles.view,
                (item && item.type.toLowerCase() === "document") ||
                  (item && item.type.toLowerCase() === "archive") ||
                  (item && item.type.toLowerCase() === "audio")
                  ? styles.iconView
                  : ""
              )}
            >
              <div className={styles.box}>
                {item ? <Webfile item={item} type={item.type} /> : ""}
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  const getContent = () => {
    if (item.type.toLowerCase() !== "story") {
      return (
          <div>
          {getHeader()}

          <div className={styles.mainContent}>
            <ViewMedia item={item} location={location} groupDisplay={groupDisplay}></ViewMedia>
          </div>
          </div>
      )
    } else {
      return <ViewStory item={item} location={location} groupDisplay={groupDisplay}>{getHeader()}</ViewStory>
    }
  }

  if (item) {
    return (
      <div key={item._id} className={styles.wrapper}>
        <div className={styles.mainContent}>{getContent()}</div>
      </div>
    )
  } else {
    return (
        <GenericMessage message={message} type={messageType} />
    )
  }
}

export default DetailedFile
