import { SelectedFilters, StateProvider } from "@appbaseio/reactivesearch"
import classNames from "classnames"
import PropTypes from "prop-types"
import moment from "moment"
import { navigate } from "gatsby"
import React, { useState, useEffect } from "react"
import { FaArrowLeft } from "react-icons/fa"
import { MdClose, MdCheck } from "react-icons/md"
import useSuggestedKeywords from "../../../hooks/use-suggested-keywords"
import FilterFiles from "../../search/filter-files/filter-files"
import styles from "./index.module.scss"
import _ from "lodash"
import { useForm } from "react-form"
import Button from "../../common/button/button"
import { getGroup, updateGroups } from "../../../services/group"
import auth from "../../../services/auth"
import TextField from "../../common/form/text-field/text-field"
import TextArea from "../../common/form/text-area/text-area"
import RenderGroupMedia from "../../common/form/render-group-media/render-group-media"
import ModalWindow from "../../common/modal-window/modal-window"
import AttachMedia from "../../common/attach-media/attach-media"
import FeaturedMediaDisplay from "../../media-output/featured-photos-display/featured-photos-display"
import { MdError } from "react-icons/md"
import MDSpinner from "react-md-spinner"

const SearchGroupEditContent = ({gid, fnEditState, fnEditFlag}) => {
  const propsWrapper = { props: null }
  const [isOpen, toggleOpen] = useState(false)
  const [addModalOpen, setAddModalOpen] = useState(false)
  const [groups, setGroups] = useState([])
  const [group, setGroup] = useState({})
  const [media, setMedia] = useState([])
  const [submitAttempted, setSubmitAttempted] = useState()
  const [suggestedKeywords, updateKeywords] = useSuggestedKeywords()
  const [lastMultiValue, setLastMultiValue] = useState(null)
  const [activeFilters, setActiveFilters] = useState(null)
  const [showMessage, setShowMessage] = useState(false)
  const [error, setError] = useState()
  const [success, setSuccess] = useState()
  const searchFns = {}
  let _searchState = {}

  const authObj = auth.getAuth()

  const onClearClick = () => {
    // this clears the elastic search query but doesn't properly clear all the inputs
    if (searchFns.clearAll) {
      searchFns.clearAll()
    }
  }

  const onMediaAttached = attachedMedia => {
    var addedMedias = []
    addedMedias.push(attachedMedia)
    const newMed = _.union(media, addedMedias)
    console.log("newMed:", newMed)
    setMedia(newMed)
    setAddModalOpen(false)
  }

  useEffect(() => {
    getGroup(gid).then(group => {
      setGroup(group)
    })
  }, [])

  const getFilterOptionsMarkUp = () => {
    return (
      <div className={classNames(styles.filter, isOpen ? "" : styles.close)}>
        <button onClick={() => toggleOpen(!isOpen)} className={styles.closeBtn}>
          <FaArrowLeft /> Close
        </button>

        <div className={styles.filterFiles}>
          <FilterFiles
            onClearHandler={onClearClick}
            availableFilters={[
              "type",
              "date",
              "location",
              "copyright",
              "source",
              "people",
            ]}
          />
        </div>
      </div>
    )
  }
  const useContext = React.createContext()
  const { data, editData } = useContext

  const formInstance = useForm({
    debugForm: false,
    onSubmit: values => {
      const groupMedi = group.media
      const NewMedias = _.union(media, groupMedi)
      const updatedObj = {
        ...values,
        media: NewMedias,
      }

      updateGroups(gid, updatedObj).then(
        result => {
          if (result.error) {
            setShowMessage(true)
            setError(result.error)
          } else {
            setShowMessage(true)
            setSuccess()
            setGroups(groups)

            setTimeout(() => {
              fnEditState()
              fnEditFlag(true)
            }, 4000)
          }
        },
        error => {
          setShowMessage(true)
          setError("Error")
        }
      )
    },
    validate: (values, instance) => {
      setShowMessage(false)
      setSubmitAttempted(false)
    },
  })

  const onModalClose = () => {
    setAddModalOpen(false)
  }

  const getEditError = () => {
    if (showMessage) {
      if (error) {
        return (
          <div className={styles.mediaError}>
            <MdError /> {error}
          </div>
        )
      } else {
        return (
          <div className={styles.mediaSuccess}>
            <MDSpinner /> {success}
          </div>
        )
      }
    }
  }

  const onCancelClick = () => {
    fnEditState()
    // navigate(`/view-my-group?gid=${currentParamId}`)
  }

  const { Form, meta, pushFieldValue } = formInstance

  const onMediaRemove = file => {
    const NewMedias = _.union([], _.pull(media, file))
    setMedia(NewMedias)
  }

  const onMediaAdd = files => {
    var addedMedias = []
    _.map(files, function(file) {
      addedMedias.push(file)
    })

    const NewMedias = _.union(NewMedias, addedMedias)
    setMedia(NewMedias)
  }

  const onAddClick = () => {
    setAddModalOpen(true)
  }

  // TODO this could maybe be it's own component?
  const getActiveFiltersMarkup = () => {
    return (
      <div className={styles.keywords}>
        <div className={styles.selected}>
          <div>
            <Form>
              <div className={styles.alignbuttons}>
                <div className="textarea">
                  {getEditError()}
                  <TextField
                    label="Title"
                    field="title"
                    type="text"
                    defaultValue={group.title}
                    validate={value => {
                      return !value ? "This is required!" : false
                    }}
                    mandatory
                  />
                  <TextArea
                    label="Description"
                    field="groupDescription"
                    type="text"
                    defaultValue={group.groupDescription}
                    validate={value => {
                      return !value ? "This is required!" : false
                    }}
                    mandatory
                  />

                  <span
                    className="group-createdAt"
                    styles={{
                      fontSize: "small",
                      display: "flex",
                      marginLeft: "15px",
                    }}
                  >
                    <h6>
                      <b>CreatedAt:</b>
                    </h6>
                    <h6>
                      {moment(group.createdAt)
                        .format("MMM DD, YYYY")
                        .toString()}
                    </h6>
                  </span>
                  <br />
                  <br />
                </div>

                <div className={styles.btncont}>
                  <div>
                    {addModalOpen === true ? (
                      <ModalWindow
                        title="Insert Image"
                        closeHandler={onModalClose}
                      >
                        <AttachMedia
                          onMediaAttached={onMediaAttached}
                          mediaType={"Photo"}
                        />
                      </ModalWindow>
                    ) : null}
                    <Button
                      id="add-media"
                      label="Add Media"
                      type="button"
                      prependIcon="MdAdd"
                      btnStyle="primary"
                      btnSize="large"
                      onClick={onAddClick}
                      style={{width: '100%'}}
                    />
                  </div>

                  <div
                    className="btns"
                    style={{
                      display: "flex",
                      marginTop: "25px",
                      marginLeft: "0px"
                    }}
                  >
                    <span
                    >
                      <Button
                        id="save-group"
                        label="Save"
                        type="submit"
                        prependIcon="MdSave"
                        btnStyle="secondary"
                        btnSize="medium"
                      />
                    </span>

                    <span
                      style={{
                        marginLeft: "15px",
                      }}
                    >
                      <Button
                        id="cancel-group"
                        label="Cancel"
                        type="button"
                        prependIcon="MdCancel"
                        btnStyle="secondary"
                        btnSize="medium"
                        onClick={onCancelClick}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Form>
      <div className={classNames(styles.wrapper, isOpen ? styles.fixed : "")}>
        {/* {getHeaderMarkUp()} */}
        <div className={styles.container}>
          {/* {getFilterOptionsMarkUp()} */}
          <div className={styles.medias}>
            <div>
              {getActiveFiltersMarkup()}
              <Form>
                <div>
                  <FeaturedMediaDisplay
                    medias={media}
                    mediaType="Photo"
                    handleRemoveFn={onMediaRemove}
                    handleAddFn={onMediaAdd}
                  ></FeaturedMediaDisplay>
                </div>
                {group.title && (
                  <RenderGroupMedia
                    handleRemoveFn={onMediaRemove}
                    handleAddFn={onMediaAdd}
                    medias={media}
                    mediaType={"Photo"}
                    group={group.title}
                    groupMedia={group.media}
                    id={gid}
                  />
                )}
              </Form>
            </div>
          </div>
        </div>
      </div>

      <svg display="none">
        <path
          id="filter-icon"
          d="M18.614,18.786a2.537,2.537,0,0,1-4.8,0H3.357a.857.857,0,0,1,0-1.714H13.814a2.537,2.537,0,0,1,4.8,0h1.029a.857.857,0,0,1,0,1.714Zm-3.257-.857a.857.857,0,1,0,.857-.857.81.81,0,0,0-.857.857Zm-1.457-12a2.537,2.537,0,0,1-4.8,0H3.357A.81.81,0,0,1,2.5,5.071a.81.81,0,0,1,.857-.857H9.1a2.537,2.537,0,0,1,4.8,0h5.743a.81.81,0,0,1,.857.857.81.81,0,0,1-.857.857Zm-3.257-.857a.857.857,0,1,0,1.714,0,.857.857,0,1,0-1.714,0Zm9,7.286H9.186a2.537,2.537,0,0,1-4.8,0H3.357a.857.857,0,1,1,0-1.714H4.386a2.537,2.537,0,0,1,4.8,0H19.643a.857.857,0,1,1,0,1.714ZM5.929,11.5a.81.81,0,0,0,.857.857.857.857,0,1,0,0-1.714.81.81,0,0,0-.857.857Z"
          transform="translate(-1.834 -1.5)"
        />
      </svg>
    </Form>
  )
}
SearchGroupEditContent.propTypes = {
  setEditStateFn: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
}

export default SearchGroupEditContent
