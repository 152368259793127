import { navigate } from "gatsby"
import PropTypes from "prop-types"
import React, { useState }  from "react"
import styles from "./selection-card.module.scss"
import { MdBookmark } from "react-icons/md"
import classNames from "classnames"
import moment from "moment"
import Thumbnail from "../../../common/display/thumbnail/thumbnail"
import ModalWindow from "../../../common/modal-window/modal-window"
import DetailedFile from "../../../media-output/detailed-file/detailed-file"

const SelectionCard = ({ data, selectionClickFn, className, showPlaceHolder, groupDisplay }) => {
  // console.log("Clicked Selection: ", data)
  const [modalOpen, setModalOpen] = useState(false)

  const handleItemClick = clickEvent => {

    if (selectionClickFn) {
      selectionClickFn(data)
    } else {

      setModalOpen(true)
      // navigate(`/app/detailed-file/${data.type}/${data.id}`, {
      //   state: { id: data.id, type: data.type, href: window.location.href },
      // })

    }
    // if (selectionClickFn) {
    //   selectionClickFn(data)
    // } else {
    //   console.log("SelectionCard ERROR: no click handler defined")
    // }
  }

  const getModalWindow = () => {
    const onModalClose = () => {
      setModalOpen(false)
    }

    if (modalOpen) {
      return (
        <ModalWindow closeHandler={onModalClose}>
          <DetailedFile 
            id={data.id}
            type={data.type}
            location={''}
            groupDisplay={groupDisplay}
          />
        </ModalWindow>
      )
    } else {
      return ""
    }
  } 

  const getDateValue = () => {
    if (data.date && data.date !== "18700101") {
      if (data.date.length === 6) {
        return moment(data.date, "YYYYMM")
          .format("MMM-YYYY")
          .toString()
      } else if (data.date.length === 8) {
        return moment(data.date, "YYYYMMDD")
          .format("DD-MMM-YYYY")
          .toString()
      }
    } else {
      return ""
    }
  }

  if (data && data.type) {
    return (
      <div
        id={"selection-card" + data._id}
        className={classNames(styles.wrapper, className)}
        key={data._id}
        onClick={handleItemClick}
      >
        {getModalWindow()}        
        <div
          className={styles.thumbnailWrap}
          id={"cardThumbnailWrapper" + data._id}
        >
          <Thumbnail data={data} showPlaceHolder={showPlaceHolder} standardCard={false}/>
        </div>
        <div className={styles.infoWrap}>
          <div
            id={"cardTitle" + data._id}
            className={styles.title}
            key={data._id}
            onClick={handleItemClick}
          >
            <div
              className={styles.anchor}
              id={"/detailed-file-view?mediaId=" + data._id}
            >
              {data.title}
            </div>
          </div>
          <div id={"cardPhotoDate" + data._id} className={styles.date}>
            {getDateValue()}
          </div>
        </div>
      </div>
    )
  } else {
    return <div className={styles.wrapper}>Invalid Data</div>
  }
}

SelectionCard.propTypes = {
  data: PropTypes.object.isRequired,
  selectionClickFn: PropTypes.func,
  className: PropTypes.string,
}

export default SelectionCard
