import React, { useState } from "react"
import SearchGroupContent from "../../search/search-group-content/search-group-content"
import SearchGroupEditContent from "../../search/search-groupedit-content"
import SearchWrapper from "../../common/search-wrapper/search-wrapper"

import _ from "lodash"

const ViewGroup = ({ gid, type, item}) => {

  const [editState, setEditState] = useState(false)
  const [editFlag, setEditFlag] = useState(false)
  const [lastRefreshTime, setLastRefreshTime] = useState('')  

  const fnEditState = () => {
    setEditState(!editState)
    setTimeout(() => {
        setLastRefreshTime(new Date())
    }, 4000)
  }

  const fnEditFlag = (edit) => {
    console.log('fnEditFlag')
    setEditFlag(edit)
  }

  const getGroupContent = () => {
    
    if (editState === false) {
      return (
        <SearchWrapper index="media" searchPath={"search"}>
          <SearchGroupContent
            gid={gid}
            editFlag={editFlag}
            fnEditState={fnEditState}
            fnEditFlag={fnEditFlag}
            lastRefreshTime={lastRefreshTime}
            groupDisplay
          />
        </SearchWrapper>  
      )
    } else if (editState === true) {
      return (
        <SearchWrapper index="media" searchPath={"search"}>   
          <SearchGroupEditContent
            gid={gid}
            fnEditState={fnEditState}
            fnEditFlag={fnEditFlag}
          />
        </SearchWrapper>     
      )
    }
  }

  return getGroupContent()
}

export default ViewGroup
