import classNames from "classnames"
import _ from "lodash"
import util from "../../../services/util"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { MdCancel, MdCheckCircle } from "react-icons/md"
import Thumbnail from "../../common/display/thumbnail/thumbnail"
import ImageEditor from "../../common/image-editor/image-editor"
import SelectionCard from "../cards/selection-card/selection-card"
import styles from "./featured-photos-display.module.scss"
import StandardCard from "../cards/standard-card/standard-card"
const FeaturedMediaDisplay = ({
  id,
  medias,
  activeMedia,
  setActiveFn,
  handleRemoveFn,
  handleEditFn,
  formWrappers,
}) => {

  const onMediaClick = (event, media) => {
    handleEditFn(media)
    if (setActiveFn) {
      console.log('onMediaClick')
      event.stopPropagation()
      setActiveFn(media)
    }
  }

  const showImageEditor = (data) => {
    console.log('showImageEditor', data)
    return <ImageEditor data={data}/> 
  }

  const onRemoveClick = (event, media) => {
    event.stopPropagation()
    handleRemoveFn(media)
  }

  const isAddRemoveHidden = () => {
    return formWrappers && formWrappers.length > 0
  }

  const isCheckHidden = (mediaData, icon) => {
    // we don't show a Check if media is the active one or if not enough information
    var isActiveMedia = activeMedia && mediaData._id === activeMedia._id
    if (isActiveMedia || !formWrappers || formWrappers.length === 0) {
      return true
    }
    var wrapper = _.find(formWrappers, { id: mediaData._id })
    if (wrapper && wrapper.formInstance) {
      // form is invalid so hide check
      return (
        !wrapper.formInstance.meta.isValid ||
        !wrapper.formInstance.meta.isTouched
      )
    } else {
      return true
    }
  }

  return (
    <ul id={id ? id : "collectionMediaList"} className={styles.thumbnailBatch}>
      {_.map(medias, (mediaData, idx) => (
        <li
          key={mediaData._id}
          // onClick={event => {
          //   onMediaClick(event, mediaData)
          // }}
          className={
            activeMedia && mediaData._id === activeMedia._id
              ? styles.active
              : ""
          }
        >
          <MdCheckCircle
            className={classNames({
              [styles.checkIcon]: true,
              [styles.hidden]: isCheckHidden(mediaData),
            })}
          />
          <MdCancel
            id={(id ? id : "collectionMediaList") + "-" + idx + "-remove"}
            onClick={event => {
              onRemoveClick(event, mediaData)
            }}
            className={classNames({
              [styles.closeIcon]: true,
              [styles.hidden]: isAddRemoveHidden(),
            })}
          />
          <SelectionCard
            key={idx}
            data={util.fixUrls(mediaData)}
            selectionClickFn={null}
          />
        </li>
      ))}
    </ul>
  )
}

FeaturedMediaDisplay.propTypes = {
  id: PropTypes.string,
  medias: PropTypes.array.isRequired,
  className: PropTypes.string,
  activeMedia: PropTypes.object,
  setActiveFn: PropTypes.func,
  handleRemoveFn: PropTypes.func,
  handleAddFn: PropTypes.func,
  formWrappers: PropTypes.array,
}

export default FeaturedMediaDisplay