import { ReactiveList } from "@appbaseio/reactivesearch"
import PropTypes from "prop-types"
import React , { useState } from "react"
import StandardCard from "../../cards/standard-card/standard-card"
import "./render-group-items.module.scss"
import Empty from "../../../common/empty/empty"
import util from "../../../../services/util"

const RenderGroupItems = ({ defaultQueryFn, groupData }) => {
  const [mediaPlayingId, setMediaPlayingId] = useState('')
  const [mediaPlaying, setMediaPlaying] = useState(false)

  const fnSetMediaPlayingId = (id) => {
    console.log('Media Playing Id ', id)
    setMediaPlayingId(id)
  }

  const fnSetMediaPlaying = (playing) => {
    setMediaPlaying(playing)
  }

  const groupMedia = groupData.media

  const renderSelectionCard = data => {

    return (
      <div className={data.length < 4 ? "cardLess" : "cards"}>
        {data.map(item => (
          <StandardCard
            key={item._id}
            data={util.fixUrls(item)}
            className={data.length < 5 ? "cardItem" : ""}
            mediaPlayingId={mediaPlayingId}
            fnSetMediaPlayingId={fnSetMediaPlayingId}
            mediaPlaying={mediaPlaying}
            fnSetMediaPlaying={fnSetMediaPlaying}
            groupDisplay={true}
          />
        ))}
      </div>
    )
  }

  return (
    <>
      <div className="listSelectItems">
        <div className="container">
          <ReactiveList
            defaultQuery={defaultQueryFn}
            // renderItem={true}
            className="wrap"
            componentId="SearchResult"
            dataField="name"
            title="ReactiveList"
            stream={false}
            // pagination={true}
            paginationAt="bottom"
            scrollOnChange={false}
            pages={5}
            size={10}
            react={{
              and: {
                and: ["searchKeywords", "searchTitle"],
              },
            }}
            showLoader={false}
            render={({ Loading, error, data }) => {
              if (Loading) {
                return <div>Fetching Result.</div>
              }
              if (error) {
                return (
                  <div>
                    Something went wrong! Error details {JSON.stringify(error)}
                  </div>
                )
              }
              return renderSelectionCard(data)
            }}
            showResultStats={false}
            renderNoResults={function() {
              return <Empty title="No results found" content="" />
            }}
          />
        </div>
      </div>
    </>
  )
}

RenderGroupItems.propTypes = {
  onCardClick: PropTypes.func,
}

export default RenderGroupItems
