/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "../header/header"
import { ToastContainer } from "react-toastify"
import "./layout.scss"
import ModalWrapper from "../../common/modal/modal-wrapper/modal-wrapper"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  console.log("rendering Layout")

  return (
    <>
      {/* Every layout needs this to be able to display reusable modal windows (e.g. alertModal) */}
      <ModalWrapper />
      <Header siteTitle={data.site.siteMetadata.title} />
      <main> {children} </main>
      <ToastContainer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
